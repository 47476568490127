import React, { useState, useEffect, useRef, PureComponent } from 'react'
import Modal from 'react-bootstrap/Modal'
import styles from './ScannerModal.module.css'
import lupa from './img/lupa.svg'
import scanner1 from './img/Trazado 42230.svg'
import scanner2 from './img/Trazado 42230 (2).svg'
import { thisExpression, throwStatement } from '@babel/types';
import info from './img/256px-Info_icon.svg.png'
import Swal from 'sweetalert2'


export default class ScannerModal extends PureComponent{
constructor(props){
  super(props)
  this.state={
    scanner:false,
    numPed:"",
    optionsSelect:[]
  }

}

handleSearchPedido = (numPed) =>{
    this.setState({numPed:numPed})
  const pedArray = this.props.list_orders.filter(element => element.no_pedido.indexOf(numPed.replace(/ /g,"")) != -1);
  
  this.state.optionsSelect = pedArray
  this.forceUpdate();
 
}


   validNumber = (name) => {
    Swal.fire({
      title: name,

      imageUrl: info,
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Custom image',
      showConfirmButton: false,
      timer: 1500
    })
  }
   handleSearch = (e) => {
    if(e.target.value.length > 1){
      this.props.guia ?
        this.handleSearchGuia(e.target.value)
      :
    
        this.handleSearchPedido(e.target.value)
      
    }
  }
   validateKey = (event) => {
    event.preventDefault();


    this.props.guia ? this.numPed.length === 0 ? this.validNumber("Escribe tu número de guía") : this.props.confirmarGuia(this.state.numPed)
      :
      this.state.numPed.length === 0 ? this.validNumber("Escribe tu número de pedido") : this.props.handlePedido(this.state.numPed)

   
  }
render(){
  return (
    <>
    
      <Modal size="lg" show={this.props.showModalScanner} onHide={this.props.handleModalScanner} >
        <Modal.Header closeButton>
          {this.props.guia ?
            <Modal.Title>¿Qué guía quieres confirmar?</Modal.Title>
            :
            <Modal.Title>¿Qué pedido quieres cancelar?</Modal.Title>
          }

        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => this.validateKey(e)}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5%", marginBottom: "5%" }}>
              <img style={{ cursor: "pointer" }} onClick={() => { this.setState({scanner:!this.state.scanner}); document.getElementById("input").focus() }} src={this.state.scanner ? scanner2 : scanner1} />
              <div style={{ position: "relative", width: "35%", paddingLeft: "5%" }}>
                {/* <input value={this.state.numPed} onChange={(e) => { this.setState({numPed:e.target.value})  }} id="input" className={styles.inputOrden} placeholder={this.props.guia ? "Escribe tu número de guía" : "Escribe tu número de pedido"} type="text" autoFocus></input><img onClick={(e) => { this.validateKey(e) }} className={styles.lupa} src={lupa} /> */}

                <input autoFocus list="browsers" onChange={(e) => { this.handleSearch(e) }} placeholder={this.props.guia ? "Escribe tu número de guía" : "Escribe tu número de pedido"} className={styles.inputOrden} name="browser" /><img onClick={(e) => { this.validateKey(e) }} className={styles.lupa} src={lupa} />
                <datalist id="browsers">
                
                {this.state.optionsSelect.length > 0 ? 
                 this.state.optionsSelect.map((element,index)=>{
                  return(
                    <option value={element.no_pedido} />
                  )
                })  
              :
              ""
              }
                 
                
                </datalist>


              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
  
}



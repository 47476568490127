import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import pedidoService from '../../Services/pedidos.service';
import ModalAlerta from '../ModalAlertas';
import { ModalAlertaError } from '../ModalAlertas/alertasError';
import Swal from 'sweetalert2'
import paloma from '../Pedidos/img/Trazado 42223.svg'
import { ClipLoader } from 'react-spinners';

export default class Boxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingClio: false,
      boxes: []
    }
  }
  prevStep = (e) => {
    e.preventDefault();
    this.props.prevStep();
  }
  close = e => {
    e.preventDefault();
    this.props.closeModal();
  }


  actualizarInformacionPedido = e => {
    e.preventDefault();

    if (this.props.weightTotal > 0) {

      var arrayBoxes = [];

      this.props.boxes.forEach(element => {

        if (element.size === 'sm') {

          element.items.forEach(item => {
            arrayBoxes.push({
              "weight": item.weight,
              "quantity": 1,
              "typeId": 1
            });
          });

        } else if (element.size === 'md') {

          element.items.forEach(item => {
            arrayBoxes.push({
              "weight": item.weight,
              "quantity": 1,
              "typeId": 2
            });
          });

        } else {

          element.items.forEach(item => {
            arrayBoxes.push({
              "weight": item.weight,
              "quantity": 1,
              "typeId": 3
            });
          });
        }
      });

      const menoresDeCero = arrayBoxes.filter(i => i.weight <= 0)

      if (menoresDeCero.length === 0) {
        const confirmarCantidad = this.props.dataProducts.detail.map(e => {
          e.confirmedQuantity = e.Quantity
          return e
        });

        let arreglo_detalle = []

        confirmarCantidad.forEach(detail => {
          console.log(detail);
          arreglo_detalle.push(
            {

              "Id": detail.id,
              "ItemMasterID": detail.ItemMaster ?.name,
              "confirmedQuantity": detail.Quantity,
              "UnitPrice": detail.UnitPrice,
              "Quantity": detail.Quantity,
              "ExtendedAmount": detail.ExtendedAmount,
              "MarketingProgram": detail.MarketingProgram,
              "PercentageMarketingProgram": detail.PercentageMarketingProgram,
              "AmountMarketingProgram": detail.AmountMarketingProgram,
              "TaxBaseAmount": detail.TaxBaseAmount,
              "IVATax": detail.IVATax,
              "IEPSTax": detail.IEPSTax,
              "TotalTaxes": detail.TotalTaxes,
              "TotalAmount": detail.TotalAmount
            }
          )
        });

        const actualizarPedido = {
          "Header": {
            "id": this.props.dataProducts.id,
            "status": 3,
            "OrderPackaging": arrayBoxes,
            "Detail": arreglo_detalle
          }
        }

        this.setState({ loading: true })
        this.setState({ loadingClio: true })

        pedidoService.actualizarConfirmacionPedido(actualizarPedido)
          .then(res => {

            this.setState({ loading: false })
            this.setState({ loadingClio: false })
            window.localStorage.setItem("id", actualizarPedido.Header.id)
            Swal.fire({
              title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Confirmado exitosamente </p> ",
              imageUrl: paloma,
              imageWidth: 300,
              imageHeight: 100,
              imageAlt: 'Custom image',
              showConfirmButton: true,
              confirmButtonText: 'Cerrar',
              confirmButtonColor: '#FD6920'
            }).then(res => {
              if (res) {
                this.props.closeModal();
                this.props.handleWeight();
                this.props.updateRow();
                this.props.resetData();
                this.props.closeModalResume();

              }
            });
          })
          .catch(error => {
            console.log(`Ah ocurrido un error: ${error}`);
            Swal.fire({
              title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Ocurrió un error </p> ",
              html: " <hr style=' background-color: #FF6900; size: '90px'; size='8px' ' />  <p style='color: #969393;'> Ha ocurrido un error con la dirección al generar guía, intenta más tarde </p> ",
              imageWidth: 500,
              imageHeight: 100,
              showConfirmButton: true,
              confirmButtonText: 'Cerrar',
              confirmButtonColor: '#FD6920'
            });
            this.setState({ loadingClio: false })
            this.props.closeModal();
            this.props.handleWeight();
            this.props.updateRow();
            this.props.closeModalResume();
          });

      } else {
        Swal.fire({
          title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Ocurrió un error al enviar la información </p> ",
          html: " <hr style=' background-color: #FF6900; size: '90px'; size='8px' ' />  <p style='color: #969393;'> No puedes enviar el peso de una caja con 0 Kg. </p> ",
          imageWidth: 500,
          imageHeight: 100,
          showConfirmButton: true,
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#FD6920'
        });
      }

    } else {
      Swal.fire({
        title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Ocurrió un error al enviar la información </p> ",
        html: " <hr style=' background-color: #FF6900; size: '90px'; size='8px' ' />  <p style='color: #969393;'> El peso total de las cajas no puede ser cero, verificar. </p> ",
        imageWidth: 500,
        imageHeight: 100,
        showConfirmButton: true,
        confirmButtonText: 'Cerrar',
        confirmButtonColor: '#FD6920'
      });
    }

  }

  componentDidMount() {
    let arreglo_cajas = [];
    this.props.boxes.forEach(boxes => {
      boxes.items.forEach(item => {
        arreglo_cajas.push({
          'id_caja': item.id,
          'weight': item.weight,
          'size': boxes.size,
          'name': boxes.name
        });
      });
    });
    let arreglo_acomodado = arreglo_cajas.reverse();
    this.setState({ boxes: arreglo_acomodado })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.boxes !== this.props.boxes) {
      let arreglo_cajas = [];
      this.props.boxes.forEach(boxes => {
        boxes.items.forEach(item => {
          arreglo_cajas.push({
            'id_caja': item.id,
            'weight': item.weight,
            'size': boxes.size,
            'name': boxes.name
          });
        });
      });
      let arreglo_acomodado = arreglo_cajas.reverse();
      this.setState({ boxes: arreglo_acomodado })
    }
  }

  render() {
    function renderImages(boxes, size) {
      for (let i = 0; i < boxes; i++) {
        return (
          <img src="/img/boxes/boxActive.svg" className={size} alt="box2" />
        )
      }
    }
    const { boxes } = this.state;
    return (

      <div>
        <Modal.Header >
          <Modal.Title className="ml-3">¿Cuál es el peso de las cajas?</Modal.Title>
          <span className="mr-3-mb-3"><img onClick={this.close} style={{ height: "18px", marginbuttom: "3px" }} src="/img/closebtn.svg" /></span>
        </Modal.Header>
        <Modal.Body className="border-0">
          <div className="spinner">
            <ClipLoader
              sizeUnit={"px"}
              size={80}
              color={'#FF6900'}
              loading={this.state.loadingClio}
            />
          </div>
          <Container className="boxweight-container">
            <table className="table tableBox ">
              <thead>
                <th scope="col">CAJA</th>
                <th scope="col">TAMAÑO</th>
                <th scope="col">PESO KG</th>
              </thead>
              <tbody>
                {
                  this.state.boxes.map((item, index) => (

                    <tr key={index}>
                      <td>
                        <img src="/img/boxes/boxActive.svg" className={item.size} alt="box2" />
                      </td>
                      <td> {item.name} </td>
                      <td>
                        {
                          index === 0
                            ?
                            <input
                              type="number"
                              className="inputWeight"
                              value={item ?.weight}
                              min="1" pattern="^[0-9]+"
                              step='0.01'
                              autoFocus
                              onChange={(e) => this.props.handleWeight(e.target.value, item.size, item.id_caja)} />
                            :

                            <input
                              type="number"
                              className="inputWeight"
                              value={item ?.weight}
                              min="1" pattern="^[0-9]+"
                              step='0.01'
                              onChange={(e) => this.props.handleWeight(e.target.value, item.size, item.id_caja)} />
                        }
                      </td>
                    </tr>

                  ))
                }
              </tbody>
              {/*{this.props.boxes.map(p => (
                <tbody key={p.size}>
                  {p.items ?.map( ( item, index ) => (
                    <>
                      <tr key={index}>
                        <th scope="row" ><img src="/img/boxes/boxActive.svg" className={p.size} alt="box2" /></th>
                        <td><p>{p.name}</p></td>
                        <td >
                          { index === 0 
                            ?
                              <input
                              type="number"
                              className="inputWeight"
                              value={item ?.weight.toString()}
                              min="1" pattern="^[0-9]+"
                              step='0.01'
                              autoFocus
                              onChange={(e) => this.props.handleWeight(e.target.value, p.size, item.id)} />
                            :
                              <input
                              type="number"
                              className="inputWeight"
                              value={item ?.weight.toString()}
                              min="1" pattern="^[0-9]+"
                              step='0.01'
                              onChange={(e) => this.props.handleWeight(e.target.value , p.size, item.id)} />
                          }
                          
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
                        )).reverse()}*/}
            </table>
            <div className="total-container">
              <div className="total">
                <h3>total: <span style={{ color: "#C10000" }}>{this.props.weightTotal} KG</span></h3>
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer className="border-0">

        </Modal.Footer>
        <div className="bottomm">
          {!this.state.loading && <button className="btnBack" variant="outline-warning" size="lg" onClick={this.prevStep}>
            &lt; REGRESAR
                </button>}

          {this.state.loading && <button className="btnBack" variant="outline-warning" size="lg" disabled="true">
            &lt; REGRESAR
                </button>}

          {!this.state.loading && <button className="btnBorder btnCont" variant="outline-success" size="lg" onClick={this.actualizarInformacionPedido}>
            Continuar
                </button>}

          {this.state.loading && <button className="btnBorder btnDisabled" variant="outline-success" size="lg" disabled>
            Continuar
                </button>}
        </div>
      </div>
    )
  }
}


import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import user from './img/Trazado 42400.svg'
import userNaranja from './img/Trazado 42401.svg'
// import '../AppBar/index.css';
import Avatar from '@material-ui/core/Avatar';

import { GoogleLogout } from 'react-google-login';




const styles = {
  root: {
    flexGrow: 1
  }, avatar: {
    margin: 10,
    cursor:"pointer"
  },
  bigAvatar: {
    marginRight: 20,
    width: 50,
    height: 50,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  appbar: {
    
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    zIndex: '1039',
    width: '100%',
    position: 'fixie',
    boxShadow: "0px 0px 0px 0px"


  },
  toolbar: {

    minHeight: '95px',
    ['@media only screen and (max-width: 1446px)']: {
      minHeight: '79px'
    },
    ['@media only screen and (max-width: 1024px)']: {
      minHeight: '68px'
    },
    ['@media only screen and (max-width: 768px)']: {
      minHeight: '50px'
    }
  },
  user: {
  backgroundColor:"black"
  }
};


class LogOut extends React.Component {
  constructor() {
    super()
    this.state = {
      auth: true,
      anchorEl: null,
      user: ((localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData')))) || {},
      src:user,
      srcNaranja:userNaranja
    }

  }
 

  handleChange = event => {
    this.setState({ auth: event.target.checked });

  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });

  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  logout = () => {
    
    window.localStorage.clear()
    window.location.href="/login"
  }



  render() {
    const { classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);





    return (
      <Fragment>

      

                <div style={{width:"3%", display:"flex", justifyContent:"center", cursor:"pointer",right:"11px",top: "6px",position:"absolute"}} >
                 
                  <Avatar
                    alt="Remy Sharp"
                    src={this.state.user.imageUrl? this.state.user.imageUrl:this.state.src}
                    className={classes.Avatar}
                    aria-owns={open ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"

                  />



                  <Menu

                    anchorEl={anchorEl}
                    className={classes.Menu}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.handleClose}
                  >

                    <MenuItem className="avatar" onClick={this.handleClose}>

                      <FormGroup>

                        <div style={{ padding: "0px" }} >
                          <div className="d-flex align-items-stretch">
                            <div >
                              <Avatar alt="Remy Sharp" src={this.state.user.imageUrl? this.state.user.imageUrl:this.state.srcNaranja}className={classes.bigAvatar} />
                            </div>
                            <div style={{fontSize:"1.1vw",color:"gray",fontFamily:"Gilroy-Black"}}>
                              <a style={{ fontWeight: "bold" }}><b>{this.state.user.email}</b></a><br />
                              <a>{this.state.user.name} - </a>
                              <a>{this.state.user.role}</a>
                            </div>
                          </div>
                        </div>
                      </FormGroup>


                    </MenuItem>
                    <div style={{ marginLeft: '0px',display:"flex",justifyContent:"center" }} className="row">
                    

                      <div>
                      <GoogleLogout
                          clientId="440862947785-6ichl5dm98j010rbj9b64levkvm9agqf.apps.googleusercontent.com"
                          icon={true}
                          buttonText="Cerrar sesión"
                          onLogoutSuccess={this.logout}
                       
                        >
                        </GoogleLogout>
                        
                      </div>
                    </div>
                  </Menu>
                </div>
              
            


      </Fragment>
    );
  }
}
LogOut.propTypes = {
  classes: PropTypes.object.isRequired,
};





export default withStyles(styles)(LogOut);
import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import paloma from '../Pedidos/img/Trazado 42223.svg'
import Swal from 'sweetalert2'
import pedidoService from '../../Services/pedidos.service';
import CancelListService from '../../Services/cancelList.service';

export default class OrderCanceled extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: 0,
            list_cancel: [],
            cancelReason:"",
            showalert:false,
        }
    }

    close = (e) => {
        e.preventDefault();
        this.props.closeModal()
        //this.props.clearData()
    }

    prevStep = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    continueStep = e => {
        e.preventDefault();
        this.props.nextStep();
    }
    
    incompletStep = e => {
        e.preventDefault();
        this.props.incompletStep();
    }
    
    HandleCancelarPedido = ()=>{

        if (this.state.cancelReason){

            let arreglo_detalle = []
            this.props.dataProducts[0].detail.map(detail => {
                arreglo_detalle.push(
                    {
                        "Id": detail.id,
                        "ItemMasterID": detail.ItemMaster?.name,
                        "confirmedQuantity": detail.Quantity,
                        "UnitPrice": detail.UnitPrice,
                        "Quantity": detail.Quantity,
                        "ExtendedAmount": detail.ExtendedAmount,
                        "MarketingProgram": detail.MarketingProgram,
                        "PercentageMarketingProgram": detail.PercentageMarketingProgram,
                        "AmountMarketingProgram": detail.AmountMarketingProgram,
                        "TaxBaseAmount": detail.TaxBaseAmount,
                        "IVATax": detail.IVATax,
                        "IEPSTax": detail.IEPSTax,
                        "TotalTaxes": detail.TotalTaxes,
                        "TotalAmount": detail.TotalAmount,
                        "ShortageDescription": detail.ShortageDescription
                    }
                )
            });
            const cancelado = {
                "Header": {
                    "id": this.props.dataProducts[0].id,
                    "status": 29,
                    "OrderPackaging": [],
                    "Detail": arreglo_detalle,
                    "cancelReason": this.state.cancelReason || null,           
                }
            };
            this.props.updateRow();
            pedidoService.actualizarConfirmacionPedido(cancelado)
            .then(res => {
                this.props.updateRow();
                Swal.fire({
                    title: 'Se ha cancelado el pedido correctamente',
                    imageUrl: paloma,
                    imageWidth: 300,
                    imageHeight: 100,
                    imageAlt: 'Custom image',
                    showConfirmButton: false,
                    timer: 2000
                });
            })
            .catch(error => {
                console.log(`Ah ocurrido un error: ${error}`);
            });
        } else {
            this.setState({...this.state, showalert: true })
        }
    }

    componentDidMount() {
        this.obtenerCombos();
    }

    obtenerCombos = () => {
        CancelListService
            .getAll()
            .then((cancelList) => {
                this.setState({...this.state, list_cancel: cancelList })
            })
            .catch((error) => console.log(`Ocurrio un error: ${error}`));
    };

    handleMotivoChange(e) {
        this.setState({...this.state, cancelReason: e.target.value, showalert: false });
    }

    render() {
        return <div>
            {this.props.dataProducts.length > 0 ? 
            <div>
            <Modal.Header  closeButton >
                <Modal.Title className="ml-3">Resumen de compra </Modal.Title>
                {/* <span className="mr-3-mb-3"><img onClick={(e) => this.close(e)} style={{ height: "18px", marginbuttom: "3px", cursor: 'pointer' }} src="/img/closebtn.svg" /></span> */}
            </Modal.Header>
            <Modal.Body className="border-0">
                <Container className="ordercanceled">
                    <Row>
                        <div className='no-pedido'>
                            Número de pedido:  {this.props.dataProducts[0].no_pedido}
                        </div>
                    </Row>
                    <Row>
                        <div className='no-pedido'>
                            Motivo de cancelación: 
                        </div>
                        <div className="motivocontainer" style={{ marginLeft: '10px' }}>
                            <select onChange={e => this.handleMotivoChange(e)} className="combomotivocancelar">
                                <option value={""}>Selecciona uno</option>
                                {this.state.list_cancel.map((e, i) =>
                                    <option key={i} value={e.id}>{e.name}</option>
                                )}
                            </select>
                            {this.state.showalert && <span className="required">Completa este campo para cancelar pedido</span>}
                        </div>
                    </Row>
                    <Row className='products-table'>
                        <Col xs={10} md={8}>
                            <div style={{ textAlign: 'left', marginLeft: '50px' }}>PRODUCTO</div>
                        </Col>
                        <Col xs={2} md={4}>
                            <div>CANTIDAD REQUERIDA</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            {
                                this.props.dataProducts.map((product,index)=>{
                                    return product.detail.map((element,index)=>{
                                        return (
                                            <ProductsDetails key={index} product={element} />
                                        )
                                    })
                                   
                                })
                            }
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="border-0 bottomm">
                <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Button className="btnBorder" variant="outline-danger" size="lg" onClick={this.HandleCancelarPedido}>
                    CANCELAR PEDIDO
                </Button>
                </div>
            </Modal.Footer>
        </div>
        :""    
        }
        </div>;
    }
}

function ProductsDetails({product}) {
   
    return (
        <div>
            {product.ItemMaster.name != "085069" && product.ItemMaster.name != "085070" && product.ItemMaster.name != "085071" && <Row className="mb-3">
                <Col md={8}>
                    <Row>
                        <Col md={2} style={{ padding: '10px' }} >
                            <div style={{ textAlign: 'center' }}>
                                <img src={product.ItemMaster.image} alt="product_image" style={{ maxWidth: '100%', maxHeight: '180px' }} />
                            </div>
                        </Col>
                        <Col md={10} style={{ position: 'relative' }}>
                            <div className="infoprod ml-3-mb-3">
                                <div className="title-prod">
                                    SKU: {product.ItemMaster.name}
                                </div>
                                <div className="cuantity-prod">
                                    {product.ItemMaster.description}
                                </div>
                                <div className="price-prod">
                                    {product.ItemMaster.itemMasterBrand?.name}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={4}>
                    <div className="infoprod quantity">
                        {product.Quantity}
                    </div>
                </Col>
            </Row>}

        </div>

    )
}
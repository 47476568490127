import React, { useEffect, useState, forceUpdate, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Swal from 'sweetalert2'
import './index..css'
import lupa from './img/lupa.svg'
import scanner1 from './img/Trazado 42230.svg'
import scanner2 from './img/Trazado 42230 (2).svg'
const useForceUpdate = () => useState()[1];
const ModalBusquedaGuiaConfirmar = (props) => {
    const forceUpdate = useForceUpdate();
    const [scanner, setScanner] = useState(false);
    const [numPed, setNumPed] = useState("");
    const [optionsSelect, setoptionsSelect] = useState([]);
    const refInput = useRef()

    const validNumber = (name) => {
        Swal.fire({
            title: name,
            type: 'info',
            showConfirmButton: false,
            timer: 2500
        });
    };

    const handleSearchGuia = (numGuia) =>{
        setNumPed(numGuia)
        const pedArray = props.list_orders.filter(element => element.guia.shippingTracking.indexOf(numGuia.replace(/ /g,"")) != -1);
        
        setoptionsSelect(pedArray) 
    
        forceUpdate();
       
      }

    const validateKey = (event) => {
        event.preventDefault();
        setNumPed(refInput.current.value)
        if (numPed.length === 0) {
            validNumber("Escribe tu número de guía")
        } else {
            props.handleModalGuiaBuscar(numPed)
            setNumPed('');
        }
    };

    const cerrarModal = () => {
        setNumPed("");
        props.closeModalGuiaBuscar();
    };

    return (
        <>
            <Modal size="lg" show={props.showModalGuiaBuscar} onHide={cerrarModal}>
                <Modal.Header closeButton>

                    <Modal.Title>¿ Qué guía quieres confirmar ?</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => validateKey(e)}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5%", marginBottom: "5%" }}>
                            <img style={{ cursor: "pointer" }}
                                onClick={() => { setScanner(!scanner); document.getElementById("input").focus() }}
                                src={scanner ? scanner2 : scanner1} alt='' />

                            <div style={{ position: "relative", width: "35%", paddingLeft: "5%" }}>
                              



                                <input autoFocus ref={refInput} list="browsers" onChange={(e) => { handleSearchGuia(e.target.value) }} placeholder="Escribe tu número de guía" className='inputOrden' name="browser" /><img onClick={(e) => { validateKey(e) }} className='lupa' src={lupa} />
                                <datalist id="browsers">

                                    {optionsSelect.length > 0 ?
                                        optionsSelect.map((element, index) => {
                                            return (
                                                <option value={element.guia.shippingTracking} />
                                            )
                                        })
                                        :
                                        ""
                                    }


                                </datalist>
                            </div>



                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default ModalBusquedaGuiaConfirmar;
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ConfirmarGuiaService from "../../Services/confirmarGuia.service";
import Swal from 'sweetalert2'
import './index..css'
import paloma from '../Pedidos/img/Trazado 42223.svg'
import Button from 'react-bootstrap/Button';
import { ClipLoader } from 'react-spinners';
const ModalImprimirGuia = (props) => {
    const [button, setButton] = useState(false)
    const [loadingClio, setloadingClio] = useState(false)

    const imprimirGuias = () => {
        setloadingClio(true)
        if (props.rowSelected.length === 1) {
            ConfirmarGuiaService.postImprimirGuia(props.rowSelected[0].id)
                .then(response => {
                    console.log(response);
                    window.localStorage.setItem("id", props.rowSelected[0].id)
                    props.handleModalGuia()
                    props.obtenerPedidos()
                    setloadingClio(false)
                    setButton(false)
                    Swal.fire({
                        title: 'Guías impresas',
                        imageUrl: paloma,
                        imageWidth: 300,
                        imageHeight: 100,
                        imageAlt: 'Custom image',
                        showConfirmButton: false,
                        timer: 2000
                    });

                })
                .catch(error => {
                    console.log(error);
                    setloadingClio(false)
                    Swal.fire({
                        title: 'Por el momento no se puede confirmar la impresión de guías',
                        type: 'error',
                        confirmButtonText: 'Cerrar',
                        confirmButtonColor: 'red'

                    });
                    setButton(false)
                });
        } else {
            props.rowSelected.map((element, index) => {
                console.log(props.rowSelected)

                if (element.guia.shippingTracking === '000000') {
                    Swal.fire({
                        title: 'Error al imprimir la guía',
                        text: 'No se ha generado la guía',
                        type: 'error',
                        confirmButtonText: 'Cerrar',
                        confirmButtonColor: 'red'

                    });
                } else {
                    if (element.status === "Imprimir Guía") {
                        console.log(element.id);
                        ConfirmarGuiaService.postImprimirGuia(element.id)
                            .then(response => {
                                console.log(response);
                                
                                props.handleModalGuia()
                                props.obtenerPedidos()
                                setloadingClio(false)
                                setButton(false)
                                Swal.fire({
                                    title: 'Guías impresas',
                                    imageUrl: paloma,
                                    imageWidth: 300,
                                    imageHeight: 100,
                                    imageAlt: 'Custom image',
                                    showConfirmButton: false,
                                    timer: 2000
                                });

                            })
                            .catch(error => {
                                console.log(error);
                                setloadingClio(false)
                                Swal.fire({
                                    title: 'Por el momento no se puede confirmar la impresión de guías',
                                    type: 'error',
                                    confirmButtonText: 'Cerrar',
                                    confirmButtonColor: 'red'

                                });
                                setButton(false)
                            });

                    } else {
                        Swal.fire({
                            title: 'Error al imprimir la guía',
                            text: 'No se puede confirmar guía con estatus diferente a Imprimir Guía',
                            type: 'error',
                            confirmButtonText: 'Cerrar',
                            confirmButtonColor: 'red'

                        });
                    }
                }

            })
        }


    }






    return (
        <>
            <div className="my_modal">
                <Modal dialogClassName="my_modal" show={props.showModalGuia} onHide={props.handleModalGuia}>
                    <Modal.Header closeButton>
                        <Modal.Title>Imprimir guías</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div>
                            <div className="spinner">
                                <ClipLoader
                                    sizeUnit={"px"}
                                    size={80}
                                    color={'#FF6900'}
                                    loading={loadingClio}
                                />
                            </div>
                            <Button disabled={button}
                                variant="outline-success"
                                onClick={() => { setButton(true); imprimirGuias() }}
                                className="btnBorder">Guías Impresas</Button>
                            {props.rowSelected.map((element, index) => {
                                if (element.guia.shippingTracking === "000000" || element.guia.shippingTracking === null || element.guia.shippingTracking === undefined) {

                                } else {
                                    return (
                                        <div style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}>
                                            <iframe style={{ width: "100%", height: "485px" }} src={element.url} title="W3Schools Free Online Web Tutorials"></iframe>
                                        </div>
                                    )
                                }

                            })}




                        </div>
                    </Modal.Body>

                </Modal>
            </div>
        </>
    )
}

export default ModalImprimirGuia
import React, { Fragment, useEffect, useState } from 'react';

import Badge from '@material-ui/core/Badge';
import { IoMdNotifications } from "react-icons/io";
import { IconContext } from "react-icons/lib";
import { onMessageListener } from '../../firebase';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useHistory } from "react-router-dom";

function Notifications(props) {

    const history = useHistory ();
  
    const [state,setState] = useState({
      auth: true,
      anchorEl: null,
      notificaciones: [],
      countNotificaciones: 0
    });
 
    useEffect(() => {
        onMessageListener((payload) => {
            let newNotifications = state.notificaciones;
            newNotifications.push({...payload, visited: false});
            setState({ ...state, countNotificaciones: state.countNotificaciones + 1, notificaciones:newNotifications});
        });
    });

    useEffect(() => {
        navigator.serviceWorker.addEventListener("message", (message) => {
            if (!message.data.fcmMessageId) {
                let newNotifications = state.notificaciones;
                newNotifications.push({...message.data, visited:false});
                setState((prevState) => ({ ...prevState, countNotificaciones: prevState.countNotificaciones + 1, notificaciones:newNotifications}));
            }
        });
    },[]);

    const handleClick = (title) => {
        const pedido = title.substring(title.indexOf("#")+1).trim();
        history.push(`/pedidos-por-surtir/${pedido}`);
        let newNotifications = state.notificaciones.map((notification)=> notification.notification.title === title ? {...notification, visited:true}: notification);
        setState((prevState) => ({ ...prevState, notificaciones:newNotifications}));
    }

    const handlePopover = event => {
        state.notificaciones.length &&
        setState({ ...state, anchorEl: event.currentTarget, countNotificaciones: 0 });
    };

    const handleClose = () => {
        setState({ ...state, anchorEl: null });
    };

    const { anchorEl } = state;
    const open = Boolean(anchorEl);

    return (
      <Fragment>
        <div style={{width:"3%", display:"flex", justifyContent:"center", cursor:"pointer",right:"100px",top: "10px",position:"absolute"}} >
            <Badge badgeContent={state.countNotificaciones} color="primary" onClick={handlePopover}>
                <IconContext.Provider value={{ size: '1.5em'}}>
                    <IoMdNotifications />
                </IconContext.Provider>
            </Badge>
            <Popover
                id={'simple-popover'}
                open={open}
                anchorEl={anchorEl}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 56, left: window.innerWidth - 462 }}
                onClose={handleClose}
            >
                <div className="notificationHeader">
                    NOTIFICACIONES
                </div>
                <List>
                    {state.notificaciones.map((notification) => (
                        <Fragment>
                            <ListItem button alignItems="flex-start" onClick={() => handleClick(notification.notification.title)} className={notification.visited ? "" : "newNotification"}>
                                <div>
                                    <div className="notificationTitle">
                                        {notification?.notification?.title}
                                    </div>
                                    <div className='notificationBody'>
                                        {notification?.notification?.body}
                                    </div>
                                </div>
                            </ListItem>
                            <Divider style={{marginLeft:"21px", marginRight:"21px"}} />
                        </Fragment>
                    ))}
                </List>
            </Popover>
            
        </div>
      </Fragment>
    );
  }

  export default Notifications;

import React, { Fragment } from "react";
import "./styles/pedidos.css";
import BootstrapTable from "react-bootstrap-table-next";
import checkLogo from "./img/check.svg";
import PedidoIcon from '../Iconos/PedidoIcon'
import CajaIcon from '../Iconos/CajaIcon'
import Button from '../Button'
import pedidoService from "../../Services/pedidos.service";
import ConfirmarGuiaService from "../../Services/confirmarGuia.service";
import ConfirmarPedidoService from "../../Services/confirmarPedido.service";
import pedidoPorSurtirService from '../../Services/pedidosPorSurtir.service';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Stepper from "../stepper";
import PurchaseSumModal from '../PurchaseSumModal'
import Search from '../Search'
import ModalCaja from '../ModalDetailCajas'
import ModalPedido from '../modalDetailPedido'
import ScannerModal from '../ScannerModal/index'
import Swal from 'sweetalert2'
import { IconContext } from "react-icons/lib";
import paloma from './img/Trazado 42223.svg'
import info from '../ScannerModal/img/256px-Info_icon.svg.png'
import ModalBusquedaGuiaImprimir from "../ModalImprimirGuia/modalBusquedaGuia";
import ModalImprimirGuia from "../ModalImprimirGuia";
import { TiMediaRecord } from "react-icons/ti";
import ModalBusquedaGuiaConfirmar from "../ModalImprimirGuia/modalBusquedaGuiaConfirmar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class PedidosPorSurtir extends React.Component {

  constructor(props) {
    super(props);
    this.handlePedido = this.handlePedido.bind(this);
    this.handleGuia = this.handleGuia.bind(this)
    this.confirmarGuia = this.confirmarGuia.bind(this)
    this.handleGuiaBuscar = this.handleGuiaBuscar.bind(this);
    
    

    this.state = {
      list_orders: [],
      nu_ped: "",
      pedido: [],
      guia: false,
      contentModalPedidos: { detail: [] },
      contentModalCajas: { detail: [], orderPackaging: [] },
      showModalPedidos: false,
      showModalCajas: false,
      showPurchase: false,
      showModalBuscarGuiaImprimir: false,
      showModalBuscarGuiaConfirmar: false,
      showModalGuiaImprimir: false,
      selectPedido: false,
      showModalScanner: false,
      columns: [
        {
          dataField: "status",
          text: "Status",
          formatter: this.statusFormatter,
          align: 'left',
          style: {
            width: '180px'
          },
        },
        {
          dataField: "no_pedido",
          text: "No. Pedido",
          style: {
            width: '180px'
          },
        },
        {
          dataField: "fecha_entrada",
          text: "Fecha entrada",
          style: {
            width: '200px'
          }
        },
        {
          dataField: "no_cajas",
          text: "No. cajas",
          style: {
            width: '100px'
          }
        },
        {
          dataField: "peso",
          text: "peso kg",
          style: {
            width: '100px'
          },
          formatter: this.pesoFormatter,
        },
        {
          dataField: "paqueteria",
          text: "Paquetería",
          style: {
            width: '120px'
          }
        },
        {
          dataField: "guia.shippingTracking",
          text: "Guía",

        },
        {
          dataField: "",
          text: "Detalles",
          formatter: this.detallePedido,
          style: {
            width: '100px'
          },
          sort: true
        }
      ],
      posicion_stepper: false,
      posicion_stepper_actualizado: false


    };
    this.bootstrapTableRef=null
    this.rowSelected = []
    this.guiaParaImprimir = []
    this.guiaJson = {}
    this.optionsSelect=[]
  }
  handleGuia(g) {
    this.setState({ guia: g })

  }
  handleConfirmarPedidoGrid = () => {

    this.setState({ pedido: this.rowSelected[0] })
    if (this.rowSelected[0].status === "Por Confirmar") {
      this.handlePurchase()
    } else {
      Swal.fire({
        title: 'Error al confirmar pedido',
        text: 'Ya se ha confirmado este pedido',
        type: 'error',
        confirmButtonText: 'cerrar',
        confirmButtonColor: 'red'
      });
    }
  }

  componentDidMount() {
    this.obtenerPedidos(this.props.match.params.saleorderid);
    setInterval(() => {
      this.obtenerPedidos();
      console.log('cada minuto llama a obtenerPedidos() ')
    }, 60000)
  }

  componentDidUpdate(){
    console.log('saleorderid',this.props.match.params.saleorderid);
    if (this.props.match.params.saleorderid && this.props.match.params.saleorderid !== this.state.saleorderid) {
      this.obtenerPedidos(this.props.match.params.saleorderid);
    }
  }

  handlePurchase = () => {
    this.setState({ showPurchase: !this.state.showPurchase })
    console.log('Aqui se cierra la ventana');
  }
 

  handlePedido(pedido) {
    // console.log("num pedido");

    const ped = this.state.list_orders.filter(element => element.no_pedido === pedido.replace(/ /g,""));
    // console.log(ped[0])
    this.setState({ pedido: ped[0] })
    if(ped[0] === "undefined" || ped[0]===undefined){
      Swal.fire({
        title: 'Error al confirmar pedido',
        text: 'No existe ese pedido',
        type: 'error',
        confirmButtonText: 'cerrar',
        confirmButtonColor: 'red'
      });
    }else{
      if (ped[0].status === "Por Confirmar") {
        this.setState({
          showModalScanner: false,
          showPurchase: true
        })
      } else {
        Swal.fire({
          title: 'Error al confirmar pedido',
          text: 'Ya se ha confirmado este pedido',
          type: 'error',
          confirmButtonText: 'cerrar',
          confirmButtonColor: 'red'
        });
      }
    }
   

  }

  //metodo que hara un filtro en el cual el pedido cumpla con las siguientes condiciones
  //que el numero de guia exista y su estatus sea Imprimir Guía
  handleGuiaBuscar(guia) {

    //validacion para saber si el pedido existe con el numero de guia ingresado.
    if (this.state.list_orders.some(order => { return order.guia.shippingTracking === guia; })) {

      //Buscar en la lista de ordenes el pedido que cumpla con la sig. regla
      //que el numero de guía ingresada exista y su estatus sea Imprimir Guía
      this.guiaParaImprimir = this.state.list_orders.filter(order => {
        if (order.guia.shippingTracking === guia && order.status === 'Imprimir Guía') {
          return order
        }
      });

      //validar si la variable contiene información
      if (this.guiaParaImprimir.length) {
        //cerrar modal de busqueda y abrir modal de impresion de guía
        this.cerrarModalBuscarGuiaImprimir();
        this.setState({ showModalGuiaImprimir: true });
      } else {
        Swal.fire({
          title: 'Error al ingresar la guía',
          text: 'La guía que buscas tiene un estado diferente a Imprimir Guía. Verificar',
          type: 'error',
          confirmButtonText: 'cerrar',
          confirmButtonColor: 'red'
        });
      }

    } else {
      Swal.fire({
        title: 'Error al ingresar la guía',
        text: 'La guía que buscas no existe. Verificar',
        type: 'error',
        confirmButtonText: 'cerrar',
        confirmButtonColor: 'red'
      });
    }

  }

  //metodo para confirmar una guía, en este metodo se valida que el numero de guia ingresado
  //exista y que el estado de la guia sea 'Guía Impresa'.
  confirmarGuia(guia) {
    let array_guias = []

    //obtener todos los pedidos con guías igual 'solamente puede aplicar para las que son con 6 ceros'
    this.state.list_orders.forEach( e => {
      if(e.guia.shippingTracking === guia){
        array_guias.push(e);
      }
    });

    //validar que la guia no tenga 6 ceros 
    if(array_guias.some( a => { return a.guia.shippingTracking === '000000'}) ){
      Swal.fire({
        title: 'Error al ingresar la guía',
        text: 'No se puede confirmar este pedido por que no tiene guía. Verificar',
        type: 'error',
        confirmButtonText: 'cerrar',
        confirmButtonColor: 'red'
      });

    }else{

      //validar que la guía exista 
      if(array_guias.some( c => { return c.guia.shippingTracking === guia } )){
        console.log('Proceder');

        //obtener el pedido con el numero de guia ingresado, y con estatus 6 = 'Guía Impresa'
        let guia_confirmar = array_guias.filter( order => {
          if(order.guia.shippingTracking === guia && order.status_id === 6){ return order }
        });
        
        //validar si se obtuvo la pedido con estatus 6 'Guía Impresa ' y con guia ingresada por el usuario
        //ejecutar el servicio y enviar el pedido a confirmar su guía
        if(guia_confirmar.length){
          Swal.fire({
            title: 'Guías confirmadas',
            imageUrl: paloma,
            imageWidth: 300,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showConfirmButton: false,
            timer: 1500
          });
          ConfirmarGuiaService.postConfirmarGuia(guia_confirmar[0].id)
            .then( () => {
                this.obtenerPedidos();
                this.bootstrapTableRef.selectionContext.selected = [];
                Swal.fire({
                    title: 'Guías confirmadas',
                    imageUrl: paloma,
                    imageWidth: 300,
                    imageHeight: 100,
                    imageAlt: 'Custom image',
                    showConfirmButton: false,
                    timer: 1500
                });
        
            })
            .catch(error => {
                console.log(`Ha ocurrido un error: ${error}`) 
                Swal.fire({
                    title: 'No se puede confirmar por el momento.',
                    type: 'error',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: 'red'
                    
                });
            });
        
        
        }
        
        else{
          //Si el pedido tenía un estado diferente a 6 'Guía Impresa' mostrar un modal de error
          console.log('Error no hay guia con status_id = 6 ');
          console.log(array_guias);

          if(array_guias[0].status_id < 6 || array_guias[0].status_id === 12 || array_guias[0].status_id === 13){
            console.log('Validacion de menores a 6 o 12 ')
            console.log(array_guias[0].status_id);
            Swal.fire({
              title: 'Error al ingresar la guía',
              text: 'La guía que buscas tiene un estado diferente a Guía Impresa. Verificar',
              type: 'error',
              confirmButtonText: 'cerrar',
              confirmButtonColor: 'red'
            });
          }else{
            console.log('los demas restantes ')
            console.log(array_guias[0].status_id);
            Swal.fire({
              title: 'Error al ingresar la guía',
              text: 'Esta guía ya ha sido impresa.',
              type: 'error',
              confirmButtonText: 'cerrar',
              confirmButtonColor: 'red'
            });
          }
        }
  
      }
      
      else{
        //error cuando la guía buscada no fue encontrada
        Swal.fire({
          title: 'Error al ingresar la guía' ,
          text: 'La guía que desea confirmar no existe. Verificar',
          type: 'error',
          confirmButtonText: 'cerrar',
          confirmButtonColor: 'red'
        });
      }
    }

  }

  //cerrar modal que imprime la guía y vaciar la variable que contiene la guia que se queria imprimir.
  handleModalGuiaImprimir = () => {
    this.setState({ showModalGuiaImprimir: false })
    this.guiaParaImprimir = []
  };

  openModalPedidos = (itemPedido) => {
    this.setState({ showModalPedidos: true, contentModalPedidos: itemPedido })
  }

  openModalCajas = (itemCaja) => {
    console.log(itemCaja)
    if(itemCaja.no_cajas > 0){
      this.setState({ showModalCajas: true, contentModalCajas: itemCaja })
    }else{
      Swal.fire({
        title: 'Detalles' ,
        text: 'No se han armado cajas para este pedido',
        type: 'error',
        confirmButtonText: 'cerrar',
        confirmButtonColor: 'red'
      });
    }
  
  }

  closeModal = () => {
    this.setState({ contentModalPedidos: { detail: [] }, showModalPedidos: false, showModalCajas: false, contentModalCajas: { detail: [], orderPackaging: [] } })
  }

  handleModalScanner = () => {
    this.setState({ showModalScanner: !this.state.showModalScanner, pedido: [] })
  }

  //metodo para abrir ventana para buscar una guía para su impresión
  abrirModalBuscarGuiaImprimir = () => {
    this.setState({ showModalBuscarGuiaImprimir: true })
  };

  cerrarModalBuscarGuiaImprimir = () => {
    this.setState({ showModalBuscarGuiaImprimir: false })
  };

  //metodo para abrir ventana para buscar una guía para su confirmación
  abrirModalBuscarGuiaConfirmar = () => {
    this.setState({ showModalBuscarGuiaConfirmar: true })
  };

  cerrarModalBuscarGuiaConfirmar = () => {
    this.setState({ showModalBuscarGuiaConfirmar: false })
  };

  detallePedido = (cell, row, rowIndex, formatExtraData) => {
    
    return (
      <>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-2">Detalle pedido</Tooltip>} >
            <button className="btnm" onClick={() => this.openModalPedidos(row)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22.906}
                height={29.467}
                viewBox="0 0 22.906 29.467"
              >
                <path
                  className="btndetail"
                  d="M0 22.73v2.421a4.322 4.322 0 004.316 4.316h14.273a4.322 4.322 0 004.316-4.316V22.73zM19.798 5.583H3.108A4.324 4.324 0 000 9.727v2.421h22.906V9.727a4.324 4.324 0 00-3.108-4.144zM19.453 4.477V1.003a.941.941 0 00-.864-1H4.316a.941.941 0 00-.864 1v3.474zM.001 13.006h22.905v8.865H.001z"
                />
              </svg>
            </button>
        </OverlayTrigger>
        
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-3">Detalle cajas</Tooltip>} >
            <button className="btnm" onClick={() =>{ this.openModalCajas(row)}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22.906}
                height={29.467}
                viewBox="0 0 22.906 29.467"
              >
                <path
                  className="btndetail"
                  d="M11.155 12.291l2.379-1.577a.521.521 0 01.59 0l2.342 1.567V6.374h-5.311zM11.06 0H2.986L.33 5.311h9.843zM12.748 19.122H3.187v5.315h9.561zm-7.437 4.249H4.78a.531.531 0 110-1.062h.531a.531.531 0 110 1.062zm0-2.124H4.78a.531.531 0 010-1.063h.531a.531.531 0 110 1.063zm5.844 2.124H7.437a.531.531 0 110-1.062h3.718a.531.531 0 110 1.062zm0-2.124H7.437a.531.531 0 110-1.063h3.718a.531.531 0 010 1.063z"
                />
                <path
                  className="btndetail"
                  d="M.003 27.621h27.62V6.572l-.1-.2h-9.994v6.905a.531.531 0 01-.828.441l-2.874-1.929-2.911 1.929a.521.521 0 01-.292.09.5.5 0 01-.249-.064.522.522 0 01-.281-.467V6.372H.003zm2.124-9.03a.531.531 0 01.532-.531h10.623a.532.532 0 01.532.531v6.374a.532.532 0 01-.532.532H2.659a.532.532 0 01-.532-.532zM16.371 5.311L15.484 0h-3.347l-.886 5.311zM27.109 5.311l-2.61-5.312h-7.937l.207 1.238.668 4.074z"
                />
              </svg>
            </button>
        </OverlayTrigger>
      </>
    );
  };


  closeModal = () => {
    this.setState({ contentModalPedidos: { detail: [] }, showModalPedidos: false, showModalCajas: false, contentModalCajas: { detail: [], orderPackaging: [] } })
  }

  obtenerPedidos = (openpurchase) => {
    pedidoPorSurtirService
      .getPedidosCancelados()
      .then((pedidos) =>{
        this.setState( { posicion_stepper_actualizado: false } );
        var id =  JSON.parse(window.localStorage.getItem("id"));
       
        if(id != null){
          const upDate = pedidos.find((element,indexx)=>{return element.id === id})
          console.log("udate data row")
          console.log(upDate)
          window.localStorage.removeItem('id')
          this.rowSelected[0]=upDate
          this.setState( { posicion_stepper_actualizado: true } )
        }
       //si viene de una notificacion
       if (this.props.match.params.saleorderid && openpurchase) {
        const salesOrder = pedidos.find((element,indexx)=>{return element.no_pedido === this.props.match.params.saleorderid});
        salesOrder.status === 'Listo para surtir' ? 
        this.setState({ list_orders: pedidos, pedido: salesOrder, showPurchase: salesOrder ? true : false, saleorderid: this.props.match.params.saleorderid}) :
        this.setState({ list_orders: pedidos, showPurchase: false, saleorderid: this.props.match.params.saleorderid}) ;
       } else {
          this.setState({ list_orders: pedidos })
       }
      } )
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  statusFormatter = (cell, row) => {
    if (cell === "Incompleto") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#E30F17' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    } else if (cell === "Por Recoger") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#D951C4' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    } else if (cell === "Confirmado") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#149748' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    } else if (cell === "Guía Impresa") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#AF7E04' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    } else if (cell === "Guía Confirmada") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#2FE5BB' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    } else if (cell === "Por Confirmar") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#FCC742' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    } else if (cell === "Cobrado") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#1993D5' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    } else if (cell === "Imprimir Guía") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#FA306E' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    } else if (cell === "Por Recoger") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#D951C4' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    }else if (cell === "Entregado") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#D0D841' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
      
    }else if (cell === "Enviado a POS") {
      return (

        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#0B4CB2' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    }else if (cell === "Trabajando") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#F79B30' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    } else if (cell === "En Camino") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#8223C7' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );

    }else if (cell === "Recolectado") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#1FC3CB' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    }else if (cell === "Listo para surtir") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#07DE49' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    }else if (cell === "Esperando inventario") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#0A6074' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    }else{ 
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#ff5400' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    }
  };

  inventoryStatusFormatter = (cell, row) => {
    console.log();
    if (cell === "Pickeado") {
      return (
        <span>
          <strong style={{ color: "#E30F17" }}> &#9679; {cell}</strong>
        </span>
      );
    }
  };

  totalFormatter = (cell, row) => {
    return (
      <span>
        <strong> ${cell} </strong>
      </span>
    );
  };

  pesoFormatter = (cell, row) => {
    return (
      <span>
        <strong> {cell} Kg</strong>
      </span>
    );
  };

  handleMenuClose() {
    this.setState({
      isOpen: false,
    });
    console.log(this.state.isOpen);
  }
  handleMenuOpen() {
    this.setState({
      isOpen: true,
    });
  }

  //STEPPER AND MODAL
  //stepper
  handleCLick = (clickType) => {
    const { currentStep } = this.state;
    let newStep = currentStep;
    clickType === "next" ? newStep++ : newStep--;
    if (newStep >= 0 && newStep <= 6) console.log(clickType);
    this.setState({
      currentStep: clickType,
    });
  };

  handleModalPreticket = () => {
    this.setState({ showPreticket: !this.state.showPreticket })
  }

  //metodo para cuando se selecciona un elemento en el grid
  handleOnSelect = (row, isSelect) => {
      console.log(isSelect)

    if (isSelect) {
      this.rowSelected.push(row)
      this.setState({ posicion_stepper:  !this.state.posicion_stepper })
    } else {
      this.rowSelected = this.rowSelected.filter(x => x.id !== row.id)
      this.setState({ posicion_stepper: !this.state.posicion_stepper })
    }
    // Habilitar button ticket stepper
    if (this.rowSelected.length > 0) { this.setState({ selectPedido: true }) } else { this.setState({ selectPedido: false }) }
      console.log(this.rowSelected)
  }
  //metodo para cuando se seleccione todos los elemento en el grid
  handleOnSelectAll = (isSelect, rows) => {
    this.rowSelected = [];

    if (isSelect) {
      this.rowSelected = rows;
    } else {
      this.rowSelected = [];
    }
    if (this.rowSelected.length > 0) { this.setState({ selectPedido: true }) } else { this.setState({ selectPedido: false }) }
    console.log(this.rowSelected)
  }


  actualizarRow = () => {
    console.log('Esto se ejecuta cuando se actualize con exito')
    this.obtenerPedidos();
  };

  clearDataSelected = () => { this.rowSelected = [] }

  render() {
    const stepsArray = [
      {
        id: 1,
        image: "/img/steps/step-3.svg",
        imageActive: "/img/steps/step-3-active.svg",
        name: "Imprimir Ticket",
      },
      {
        id: 2,
        image: "/img/steps/step-4.svg",
        imageActive: "/img/steps/step-4-active.svg",
        name: "Confirmar Pedido",
      },
      {
        id: 3,
        image: "/img/steps/step-5.svg",
        imageActive: "/img/steps/step-5-active.svg",
        name: "Imprimir Guía",
      },
      {
        id: 4,
        image: "/img/steps/confirmar_guia.svg",
        imageActive: "/img/steps/confirmar_guia_active.svg",
        name: "Confirmar guia"
      }
    ];
    const { currentStep } = this.state;




    const selectRow = {
      mode: "checkbox",

      onSelect: this.handleOnSelect,
      // selectionHeaderRenderer: ({ mode, checked, disabled }) => (
      //   <img src={checkLogo} alt="check" />
      // ),
      // selectionRenderer: ({ mode, checked, disabled }) => (
      //   <label className="container">
      //     <input type={mode} />
      //     <span className="checkmark"></span>
      //   </label>
      // ),
      onSelectAll: this.handleOnSelectAll
    };

    return (
      <div className="pedidosPorSurtir">
        <header>
          Pedidos por surtir
        </header>
        <div className="steppe-container">
          <Stepper
            steps={stepsArray}
            currentStepNumber={currentStep}
            click={this.handleCLick}
            handleGuia={this.handleGuia}
            rowSelected={this.rowSelected}
            selectPedido={this.state.selectPedido}
            handleModalScanner={() => this.handleModalScanner()}
            obtenerPedidos={this.actualizarRow}
            abrirModalBuscarGuiaImprimir={this.abrirModalBuscarGuiaImprimir}
            abrirModalBuscarGuiaConfirmar={this.abrirModalBuscarGuiaConfirmar}
            handlePurchase={this.handlePurchase}
            handleConfirmarPedidoGrid={this.handleConfirmarPedidoGrid}
            clearDataSelected={this.clearDataSelected}
            posicion_stepper = { this.state.posicion_stepper }
            posicion_stepper_actualizado = {this.state.posicion_stepper_actualizado}
            statusValidTicket = "Listo para surtir"
          />
        </div>
        {/* <ReactToPrin
          showPreticket={this.state.showPreticket}
          handleModalPreticket={()=>this.handleModalPreticket()}
          rowSelected={this.rowSelected}
        /> */}
        <PurchaseSumModal
          dataSelected={this.state.pedido}
          clearData={this.clearDataSelected}
          updateRow={this.actualizarRow}
          handlePurchase={this.handlePurchase}
          showPurchase={this.state.showPurchase}
        />

        <ModalPedido
          showModal={this.state.showModalPedidos}
          closeModal={this.closeModal}
          detallesPedido={this.state.contentModalPedidos}

        />
        <ScannerModal
          showModalScanner={this.state.showModalScanner}
          handleModalScanner={() => { this.handleModalScanner() }}
          handlePedido={this.handlePedido}
          nu_ped={this.state.nu_ped}
          guia={this.state.guia}
          handleGuia={this.handleGuia}
          confirmarGuia={this.confirmarGuia}
          list_orders={this.state.list_orders}
        />

        {/*componente que abre una ventana para buscar una guía para su impresión */}
        <ModalBusquedaGuiaImprimir
          showModalGuiaBuscar={this.state.showModalBuscarGuiaImprimir}
          handleModalGuiaBuscar={this.handleGuiaBuscar}
          closeModalGuiaBuscar={this.cerrarModalBuscarGuiaImprimir}
          list_orders={this.state.list_orders}
        />

        {/*componente que abre una ventana para buscar una guía para su confirmación */}
        <ModalBusquedaGuiaConfirmar
          showModalGuiaBuscar={this.state.showModalBuscarGuiaConfirmar}
          handleModalGuiaBuscar={this.confirmarGuia}
          closeModalGuiaBuscar={this.cerrarModalBuscarGuiaConfirmar}
          list_orders={this.state.list_orders}
        />

        {/**Componente para imprimir la guia de un pedido */}
        <ModalImprimirGuia
          handleModalGuia={this.handleModalGuiaImprimir}
          obtenerPedidos={this.actualizarRow}
          showModalGuia={this.state.showModalGuiaImprimir}
          rowSelected={this.guiaParaImprimir}
          updateRow={this.actualizarRow}
        />

        <ModalCaja
          showModal={this.state.showModalCajas}
          closeModal={this.closeModal}
          detallesCajas={this.state.contentModalCajas}
        />

        <div className="table-responsive-sm">
          <div style={{ height: '60vh', overflow: 'auto', position: 'relative' }} className="OrderListTable">
            <ToolkitProvider
              keyField="id"
              data={this.state.list_orders}
              columns={this.state.columns}
              search
            >
              {(props) => (
                <Fragment>
                  <Search {...props.searchProps} />
                  <BootstrapTable
                     ref={(i) => this.bootstrapTableRef = i}
                    selectRow={selectRow}
                    {...props.baseProps}
                    keyField="id"
                  />
                </Fragment>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    );
  }
}

export default PedidosPorSurtir;

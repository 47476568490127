import API from './config';

const ConfirmarGuiaService = {

    postImprimirGuia: (saleorderid) => new Promise(
        (resolve, reject ) => {
         
            API.post('/salesorder/pedidos/imprimir-guia/'+saleorderid)
            .then( res => res.data)
            .then(data => {
                console.log(data)

               
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),

    postConfirmarGuia: (saleorderid) => new Promise(
        (resolve, reject ) => {
         
            API.post('/salesorder/pedidos/confirmar-guia/'+saleorderid)
            .then( res => res.data)
            .then(data => {
                console.log(data)

               
                resolve(data)
            })
            .catch( error => reject(error))
        }
    )

}

export default ConfirmarGuiaService;
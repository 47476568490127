import React, { Fragment } from "react";
import "./styles/historial_pedidos.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import historialService from "../../Services/historial.service";
import Search from '../Search'
import ModalDetailCajas from "../ModalDetailCajas";
import ModalPedido from "../modalDetailPedido";
import Swal from 'sweetalert2'
import { TiMediaRecord } from "react-icons/ti";
import { IconContext } from "react-icons/lib";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class Historial extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contentModalPedidos: { detail: [] },
      contentModalCajas: { detail: [], orderPackaging: [] },
      showModalPedidos: false,
      showModalCajas: false,
      list_orders: [],
      columns: [
        {
          dataField: "status",
          text: "Status",
          formatter: this.statusFormatter,
          align: 'left',
          style: {
            width: '220px'
          },
        },
        {
          dataField: "no_pedido",
          text: "No. Pedido",
          style: {
            width: '160px'
          },
        },
        {
          dataField: "articulos",
          text: "art.",
          style: {
            width: '50px'
          }
        },
        {
          dataField: "fecha_entrada",
          text: "Fecha entrada",
          style: {
            width: '200px'
          },
        },
        {
          dataField: "no_cajas",
          text: "No. cajas",
          style: {
            width: '100px'
          }
        },
        {
          dataField: "peso",
          text: "peso kg",
          style: {
            width: '100px'
          },
          formatter: this.pesoFormatter,
        },
        {
          dataField: "paqueteria",
          text: "Paquetería",
          style: {
            width: '120px'
          }
        },
        {
          dataField: "guia",
          text: "Guía",
        },
        {
          dataField: "",
          text: "Detalles",
          style: {
            width: '100px'
          },
          formatter: this.detallePedido,
          sort: true
        }
      ],

    };
  }



  componentDidMount() {
    this.obtenerHistorialPedidos();

    setInterval( ()=> { 
      this.obtenerHistorialPedidos();
      console.log('cada minuto llama a obtenerHistorialPedidos() ')
    }, 60000)
  }

  obtenerHistorialPedidos = () => {
   
    historialService
      .getAll()
      .then((pedidos) => this.setState({ list_orders: pedidos }))
      .catch((error) => console.log(`Ocurrio un error: ${error}`));

  };

  openModalPedidos = (itemPedido) => {
    this.setState({ showModalPedidos: true, contentModalPedidos: itemPedido })
  }

  openModalCajas = (itemCaja) => {
    if(itemCaja.no_cajas > 0){
      this.setState({ showModalCajas: true, contentModalCajas: itemCaja })
    }else{
      Swal.fire({
        title: 'Detalles' ,
        text: 'No se han armado cajas para este pedido',
        type: 'error',
        confirmButtonText: 'cerrar',
        confirmButtonColor: 'red'
      });
    }
  }

  closeModal = () => {
    this.setState({ contentModalPedidos: { detail: [] }, showModalPedidos: false, showModalCajas: false, contentModalCajas: { detail: [], orderPackaging: [] } })
  }

  detallePedido = (cell, row, rowIndex, formatExtraData) => {
  
    return (
      <>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-2">Detalle pedido</Tooltip>} >
          <button className="btnm" onClick={() => this.openModalPedidos(row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={22.906}
              height={29.467}
              viewBox="0 0 22.906 29.467"
            >
              <path
                className="btndetail"
                d="M0 22.73v2.421a4.322 4.322 0 004.316 4.316h14.273a4.322 4.322 0 004.316-4.316V22.73zM19.798 5.583H3.108A4.324 4.324 0 000 9.727v2.421h22.906V9.727a4.324 4.324 0 00-3.108-4.144zM19.453 4.477V1.003a.941.941 0 00-.864-1H4.316a.941.941 0 00-.864 1v3.474zM.001 13.006h22.905v8.865H.001z"
              />
            </svg>
          </button>

        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-3">Detalle cajas</Tooltip>} >
            <button className="btnm" onClick={() => this.openModalCajas(row)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22.906}
                height={29.467}
                viewBox="0 0 22.906 29.467"
              >
                <path
                  className="btndetail"
                  d="M11.155 12.291l2.379-1.577a.521.521 0 01.59 0l2.342 1.567V6.374h-5.311zM11.06 0H2.986L.33 5.311h9.843zM12.748 19.122H3.187v5.315h9.561zm-7.437 4.249H4.78a.531.531 0 110-1.062h.531a.531.531 0 110 1.062zm0-2.124H4.78a.531.531 0 010-1.063h.531a.531.531 0 110 1.063zm5.844 2.124H7.437a.531.531 0 110-1.062h3.718a.531.531 0 110 1.062zm0-2.124H7.437a.531.531 0 110-1.063h3.718a.531.531 0 010 1.063z"
                />
                <path
                  className="btndetail"
                  d="M.003 27.621h27.62V6.572l-.1-.2h-9.994v6.905a.531.531 0 01-.828.441l-2.874-1.929-2.911 1.929a.521.521 0 01-.292.09.5.5 0 01-.249-.064.522.522 0 01-.281-.467V6.372H.003zm2.124-9.03a.531.531 0 01.532-.531h10.623a.532.532 0 01.532.531v6.374a.532.532 0 01-.532.532H2.659a.532.532 0 01-.532-.532zM16.371 5.311L15.484 0h-3.347l-.886 5.311zM27.109 5.311l-2.61-5.312h-7.937l.207 1.238.668 4.074z"
                />
              </svg>
            </button>
          </OverlayTrigger>


      </>
    );
  };


  statusFormatter = (cell, row) => {
    if (cell === "Incompleto") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#E30F17' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    } else if (cell === "Por Recoger") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#D951C4' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    } else if (cell === "Confirmado") {
      return (


        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#149748' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>

      );
    } else if (cell === "Cobrado") {
      return (


        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#1993D5' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    } else if (cell === "Trabajando") {
      return (


        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#F79B30' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    } else if (cell === "Guía Impresa") {
      return (


        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#AF7E04' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell} Lista </strong>
        </span>
      );
    } else if (cell === "Enviado a POS") {
      return (

        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#0B4CB2' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    } else if (cell === "Recolectado") {
      return (


        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#1FC3CB' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    } else if (cell === "En Camino") {
      return (

        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#8223C7' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    } else if (cell === "Entregado") {
      return (


        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#D0D841' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    } else if (cell === "Imprimir Guía") {
      return (


        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#FA306E' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    } else if (cell === "Guía Confirmada") {
      return (


        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#2FE5BB' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    }
    else if (cell === "Por Confirmar") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#FCC742' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    }else if (cell === "Trabajando") {
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#F79B30' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    }
    else{
      return (
        <span>
          <strong>
            <IconContext.Provider value={{ size: '1.5em', color: '#ff5400' }}>
              <TiMediaRecord />
            </IconContext.Provider>
          </strong> <strong>{cell}</strong>
        </span>
      );
    }
  };

  inventoryStatusFormatter = (cell, row) => {
    console.log();
    if (cell === "Pickeado") {
      return (
        <span>
          <strong style={{ color: "#E30F17" }}> &#9679; {cell}</strong>
        </span>
      );
    }
  };

  totalFormatter = (cell, row) => {
    return (
      <span>
        <strong> ${cell} </strong>
      </span>
    );
  };

  pesoFormatter = (cell, row) => {
    return (
      <span>
        <strong> {cell} Kg</strong>
      </span>
    );
  };

  render() {

    const { SearchBar } = Search;

    return (
      <>
        <header className="Historial_Pedidos__header text-center">
          historial de pedidos
        </header>

        <div className="table-responsive-sm historial-table">
          <div style={{height:'65vh',overflow:'auto',position: 'relative'}} className="historial-table">
            <ToolkitProvider
              keyField="id"
              data={this.state.list_orders}
              columns={this.state.columns}
              search
            >
              {(props) => (
                <Fragment>
                  <div style={{ marginBottom: '2vh' }}>
                    <Search {...props.searchProps} />
                  </div>
                  <BootstrapTable

                    {...props.baseProps}
                    keyField="id"
                  />
                </Fragment>
              )}
            </ToolkitProvider>
          </div>
        </div>

        <ModalPedido
          showModal={this.state.showModalPedidos}
          closeModal={this.closeModal}
          detallesPedido={this.state.contentModalPedidos}

        />

        <ModalDetailCajas
          showModal={this.state.showModalCajas}
          closeModal={this.closeModal}
          detallesCajas={this.state.contentModalCajas}
        />

      </>
    );
  }
}

export default Historial;

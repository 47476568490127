import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import PedidoIncompletoModal from '../PedidoIncompletoModal';
import PurchaseResume from './PurchaseResume';
import Boxes from './Boxes';
import BoxWeight from './BoxWeight';
import './style.css';
import Swal from 'sweetalert2'

export default class index extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.resetData = this.resetData.bind(this);

        //boxes bind
        //this.setBoxes= this.setBoxes.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.handleWeight = this.handleWeight.bind(this);
        this.state = {
            show: false,
            step: 1,
            weightTotal: 0,
            boxes: [
                {
                    name: 'Chica CH',
                    size: 'sm',
                    items: [

                    ]
                },
                {
                    name: 'Mediana MD',
                    size: 'md',
                    items: [

                    ]
                },
                {
                    name: 'Grande GD',
                    size: 'lg',
                    items: [

                    ]
                }
            ]
        }
    }
    addItem(size) {
        const newState = this.state.boxes.map(box => {
            if (box.size === size) {
                return { ...box, items: [...box?.items, { id: box.items.length, weight: 0 }] }
            } else {
                return box
            }
        })

        this.setState({ boxes: newState })
    }
    removeItem(size) {
        const newState = this.state.boxes.map(box => {
            if (box.size === size) {
                return { ...box, items: box?.items.splice(1) }
            } else {
                return box
            }
        })

        this.setState({ boxes: newState })
    }

    handleWeight(weight, size, id) {

        let peso_flotante = parseFloat(weight).toFixed(3) 

        if(size === 'sm'){
            if(weight <= 4.0){
                this.colocarPeso(peso_flotante, size, id);
            }else{
                Swal.fire({
                    title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Ocurrió un error al enviar la información </p> ",
                    text: "El peso máximo de una caja chica es 4.0 kg. Verificar",
                    showConfirmButton: true,
                    type: 'error',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#FD6920'
                });
            }
 
        }else if(size === 'md'){
            if(weight <= 7.0){
                this.colocarPeso(peso_flotante, size, id);
            }else{
                Swal.fire({
                    title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Ocurrió un error al enviar la información </p> ",
                    text: "El peso máximo de una caja mediana es 7.0 kg. Verificar",
                    showConfirmButton: true,
                    type: 'error',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#FD6920'
                });
            }

        }else if(size === 'lg'){
            if(weight <= 15.0){
                this.colocarPeso(peso_flotante, size, id);
            }else{
                Swal.fire({
                    title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Ocurrió un error al enviar la información </p> ",
                    text: "El peso máximo de una caja grande es 15.0 kg. Verificar",
                    showConfirmButton: true,
                    type: 'error',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#FD6920'
                });
            }
        }
    }

    colocarPeso(weight, size, id){
        let total = 0
        const newState = this.state.boxes.map(box => {
            box.items.map(item => total += item.weight)
            if (box.size === size) {
                const newItem = box.items?.map(item => {

                    if (item.id === id) {
                        return { ...item, weight: Number(weight) }

                    } else {
                        return item
                    }
                })
                return { ...box, items: newItem }
            } else {
                return box
            }
        })
        
        this.setState({ boxes: newState })
        this.total(newState)
    }

    total = (state) => {
        let total = 0;
        state.map(box => {
            box.items.map(item => {
                total += item.weight;
            })
        })

        if(total){
            this.setState({ weightTotal: Number(total).toFixed(3) });
        }else{
            this.setState({ weightTotal: 0})
        }
        
    }

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };
    prevStep = () =>{
        const {step} = this.state;
        if(step >= 2){
            this.setState({
                step: step -1
            });

            //cambiar a cero algunas variables.
            this.setState({ 
                boxes: [
                    {
                        name: 'Chica CH',
                        size: 'sm',
                        items: [
    
                        ]
                    },
                    {
                        name: 'Mediana MD',
                        size: 'md',
                        items: [
    
                        ]
                    },
                    {
                        name: 'Grande GD',
                        size: 'lg',
                        items: [
    
                        ]
                    }
                ],
                weightTotal: 0 })
            console.log('Regresa del peso de as cajas')
        }
        else if(step == 'incompleto'){
            this.setState({
                step: 1
            });
        }
        else{
            this.handleClose();
        }
    };


    incompletStep = () => { this.setState({ step: 'incompleto' }); };

    //Show step
    showStep = () => {
        const { step } = this.state;

        if (step === 1)
            return (
                <PurchaseResume
                    closeModal={this.cerrarHandlePurchase}
                    clearData={this.props.clearData}
                    nextStep={this.nextStep}
                    prevStep = {this.prevStep}
                    incompletStep={this.incompletStep} 
                    dataProducts={this.props.dataSelected}/>
            );

        if (step === 2)
            return (<Boxes
                boxes={this.state.boxes}
                closeModal={this.handleClose}
                addItem={this.addItem}
                removeItem={this.removeItem}
                nextStep={this.nextStep}
                prevStep = {this.prevStep} />);

        if (step === 3)
            return (<BoxWeight
                boxes={this.state.boxes}
                handleWeight={this.handleWeight}
                prevStep = {this.prevStep}
                closeModal={this.handleClose}
                weightTotal={this.state.weightTotal}
                dataProducts={this.props.dataSelected}
                updateRow= {this.props.updateRow}
                resetData={this.resetData}
                closeModalResume={this.cerrarHandlePurchase}
            />);

        if (step === 'incompleto') {
            return (
                <PedidoIncompletoModal 
                dataProducts={this.props.dataSelected}
                closeModal={this.handleClose}
                prevStep = {this.prevStep}
                closeModalResume={this.cerrarHandlePurchase}
                resetData = {this.props.clearData}
                updateRow= {this.props.updateRow}/>
            )
        }
    }

    handleShow() {
        console.log(this.state)
        this.setState({ show: true })
    }

    handleClose() {
        this.setState({ show: false })
        this.setState({ step: 1, weightTotal: 0 })
        this.setState({
            boxes: [
                {
                    name: 'Chica CH',
                    size: 'sm',
                    items: [

                    ]
                },
                {
                    name: 'Mediana MD',
                    size: 'md',
                    items: [

                    ]
                },
                {
                    name: 'Grande GD',
                    size: 'lg',
                    items: [

                    ]
                }
            ]
        });
    }

    resetData(){
        this.setState({ show: false })
        this.setState({ step: 1 })
        this.setState({
            boxes: [
                {
                    name: 'Chica CH',
                    size: 'sm',
                    items: [

                    ]
                },
                {
                    name: 'Mediana MD',
                    size: 'md',
                    items: [

                    ]
                },
                {
                    name: 'Grande GD',
                    size: 'lg',
                    items: [

                    ]
                }
            ]
        });
        this.props.clearData();
        
    }

    cerrarHandlePurchase = () => {
        console.log('Cerrar modal y resetear data');
        this.props.handlePurchase()
        this.setState({
            boxes: [
                {
                    name: 'Chica CH',
                    size: 'sm',
                    items: [

                    ]
                },
                {
                    name: 'Mediana MD',
                    size: 'md',
                    items: [

                    ]
                },
                {
                    name: 'Grande GD',
                    size: 'lg',
                    items: [

                    ]
                }
            ],
            weightTotal: 0
        });
    }

    render() {
        
        return (
            <>
                <Modal show={this.props.showPurchase} onHide={this.cerrarHandlePurchase}  backdrop="static" >
                    {this.showStep()}
                </Modal>
            </>
        )
    }
}

import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import check from './img/ok.svg';
import './css/ModalAlertas.css';

class ModalAlerta extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = { show: false };
      }

    render(){
        return (
            <Fragment>
                <Modal size="sm" centered show={this.props.showModal} onHide={this.props.closeModal}>
                    <Modal.Header>
                        <Modal.Title>
                            <div className="text-center">
                                {this.props.title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <img src={check} alt='Check Logo' />
                    </Modal.Body>
                </Modal>
            </Fragment>
        )
    }
}

export default ModalAlerta;
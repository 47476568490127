import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

export default class ModalPedido extends Component {


    render() {
        let totalV = 0
        this.props.detallesPedido.detail.forEach(e => {
            totalV += parseFloat(e.Quantity)
            if(e.ItemMaster.name === '085069' || e.ItemMaster.name === '085070' || e.ItemMaster.name === '085071'){
                totalV -= 1;
            }
        });
        var parts = this.props.detallesPedido.fecha_entrada ? this.props.detallesPedido.fecha_entrada.split('-') : [];
        var fecha = new Date(parts[0], parts[1] - 1, parts[2]); 
        var options = { year: 'numeric', month: 'long', day: 'numeric' };

        const productsDisplay = this.props.detallesPedido.detail.map((product, index) => {

            if (product.ItemMaster.name !== '085069' && product.ItemMaster.name !== '085070' && product.ItemMaster.name !== '085071' ) {
                return (

                    <div key={index}>
                        <Row className="mb-3">
                            <Col md={2} style={{ padding: '10px' }} >
                                <div style={{textAlign: 'center'}}>
                                    {product.ItemMaster && <img src={product.ItemMaster.image} alt="product_image" style={{ maxWidth: '100%', maxHeight: '180px'}} />}
                                </div>
                                
                            </Col>

                            <Col md={4}>
                                <div className="infoprod ml-3-mb-3">
                                    <div className="title-prod">
                                        SKU: {product.ItemMaster ? product.ItemMaster.name : 'Nombre'}
                                    </div>
                                    <div style={{ fontSize: '18px' }} className="cuantity-prod">
                                        {product.ItemMaster ? product.ItemMaster.description : 'Descripcion'}
                                    </div>
                                    {/* <div className="price-prod">
                                    subclase
                                </div> */}
                                </div>
                            </Col>

                            <Col md={6} style={{margin: 'auto'}}>
                                <div className="text-center cuantity-prod " style={{ fontFamily: 'Gilroy Light' }}>
                                    <span> Cantidad: {parseInt(product.Quantity)}</span>
                                </div>
                                {
                                    parseInt(product.confirmedQuantity) < parseInt(product.Quantity) && this.props.detallesPedido.status === 'Incompleto'
                                    &&
                                    < >
                                        <div className="text-center cuantity-prod" style={{ fontFamily: 'Gilroy Light' }}>
                                            <span style={{color: 'red'}}> Incompleto</span>
                                        </div>

                                        <div className="text-center cuantity-prod" style={{ fontFamily: 'Gilroy Light' }}>
                                            <span style={{color: 'red'}} > Cantidad confirmada: {parseInt(product.confirmedQuantity)}</span>
                                        </div>
                                    </>

                                }

                            </Col>
                        </Row>
                    </div>
                );
            }

        });

        return (
            <div>
                <Modal show={this.props.showModal}>
                    <Modal.Header >
                        <Modal.Title style={{ marginLeft: '5%', fontSize: '18px' }}> Detalle Pedido </Modal.Title>
                        <span className="mr-3-mb-3 closeM"><img className="closeM" onClick={this.props.closeModal} style={{ height: "18px", marginbuttom: "3px", cursor: 'pointer' }} src="/img/closebtn.svg" /></span>
                    </Modal.Header>
                    <Modal.Body className="border-0">

                        <div className="col-12 col-md-12 col-lg-12 mb-4 text-center">
                            <span style={{ fontSize: '18px' }}> Número de pedido {this.props.detallesPedido.no_pedido}</span>
                        </div>

                        <div className="row align-items-center mb-4">
                            <div className="col-6 col-md-6 col-lg-6 text-center">
                                <span style={{ fontSize: '18px' }}> Fecha: {fecha.toLocaleDateString("es-ES", options)}</span>
                            </div>
                            <div className="col-6 col-md-6 col-lg-6 text-center">
                                <span style={{ fontSize: '18px' }}>Articulos vendidos: {totalV}</span>
                            </div>
                        </div>

                        <Container className="purchaseResume mt-4">
                            <Row>
                                <Col xs={12} md={12}>
                                    {productsDisplay}
                                </Col>
                            </Row>
                        </Container>

                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

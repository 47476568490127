// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAa6jGKZkQl5kbT-aU4Xv8PdG6318dr_iY",
  authDomain: "nutrisa-ecommerce.firebaseapp.com",
  projectId: "nutrisa-ecommerce",
  storageBucket: "nutrisa-ecommerce.appspot.com",
  messagingSenderId: "645016088344",
  appId: "1:645016088344:web:f00c5052b8aacbf903b86d",
  measurementId: "G-WSBY0CX9ZK"
};



export function getTokenEcommerce() {
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging();
  getToken(messaging, { vapidKey: 'BE4k4wg6x1ZvbMlhKmL3XJ2k_i9_Dbk3UVGMZU4b8RQoNXBaKuPPpVwNFNimdorMeOl6x2ZjmqYimoXmaasmv5c' }).then((currentToken) => {
    if (currentToken) {
      window.localStorage.setItem('firebaseToken', currentToken);
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}

export const onMessageListener = (handleMessage) => {
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    handleMessage(payload);
  });
}

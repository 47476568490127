import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import agua from '../PedidoIncompletoModal/img/agua.png';

export default class purchaseresume extends Component {
    constructor() {
        super()
        this.state = {
            index:0
        }
    }
    close = (e) => {
        e.preventDefault();
        this.props.closeModal()
        //this.props.clearData()
    }
    prevStep = (e) =>{
        e.preventDefault();
        this.props.prevStep();
    }
    continueStep = e => {
        e.preventDefault();
        this.props.nextStep();
    }
    incompletStep = e => {
        e.preventDefault();
        this.props.incompletStep();
    }
    componentWillMount(){
        console.log("dataProducts", this.props.dataProducts);
        let index = this.props.dataProducts.length - 1;
        this.setState({index});
    }

    render() {
        
        const productsDisplay = this.props.dataProducts.detail.map( (product, index) => {
            return (
                <div>
                    {product.ItemMaster.name!="085069" && product.ItemMaster.name!="085070" && product.ItemMaster.name!="085071" &&  <Row className="mb-3" key={index}>
                        <Col md={8}>
                            <Row>
                                <Col md={2} style={{ padding: '10px' }} >
                                    <div style={{textAlign: 'center'}}>
                                        <img src={product.ItemMaster.image} alt="product_image" style={{ maxWidth: '100%', maxHeight: '180px'}} />
                                    </div>
                                </Col>
                                <Col md={10} style={{position: 'relative'}}>
                                    <div className="infoprod ml-3-mb-3">
                                        <div className="title-prod">
                                            SKU: {product.ItemMaster.name}
                                        </div>
                                        <div className="cuantity-prod">
                                            {product.ItemMaster.description}
                                        </div>
                                        <div className="price-prod">
                                            {product.ItemMaster.itemManufacturer}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <div className="infoprod quantity">
                                {product.Quantity}
                            </div>
                        </Col>
                    </Row>}
                   
                </div>
            );
        });
        return <div>
            <Modal.Header >
                <Modal.Title className="ml-3">Resumen de compra </Modal.Title>
                <span className="mr-3-mb-3"><img onClick={(e) => this.close(e)} style={{ height: "18px", marginbuttom: "3px",  cursor: 'pointer' }} src="/img/closebtn.svg" /></span>
            </Modal.Header>
            <Modal.Body className="border-0">
                <Container className="purchaseResume">
                    <Row>
                        <div className='no-pedido'>
                            Número de pedido {this.props.dataProducts.no_pedido}
                        </div>
                    </Row>
                    <Row className='products-table'>
                        <Col xs={10} md={8}>
                            <div style={{textAlign:'left', marginLeft:'50px'}}>PRODUCTO</div>
                        </Col>
                        <Col xs={2} md={4}>
                        <div>CANTIDAD REQUERIDA</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            {productsDisplay}
                        </Col>
                    </Row>
                    {/*<div className="total-container">
                        <div className="total">
                            <h3>total: <span style={{ color: "#C10000" }}>$ {this.props.dataProducts[0].totalAmount} </span></h3>
                        </div>
    </div>*/}
                </Container>
            </Modal.Body>
            <Modal.Footer className="border-0 bottomm">
                <Button className="mr-auto ml-5 btnBorder" variant="outline-danger" size="lg" onClick={this.incompletStep}>
                    INCOMPLETO
                </Button>
                {/* <Button className="mr-0" variant="outline-warning" size="lg" onClick={this.prevStep}>
                 REGRESAR
                </Button> */}
                <Button className="mr-5 btnBorder" variant="outline-success" size="lg" onClick={this.continueStep}>
                 COMPLETO
                </Button>

            </Modal.Footer>
        </div>;
    }
}

import {  slide as Menu } from 'react-burger-menu'
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import LogoNutrisa from '../../Components/Resource/LogoNutrisa_menu.svg';
import facebook from '../../Components/Resource/ICONOSREDES/REDESSOCIALES/facebook.svg'
import instagram from  '../../Components/Resource/ICONOSREDES/REDESSOCIALES/instagram.svg';
import twitter from  '../../Components/Resource/ICONOSREDES/REDESSOCIALES/twitter.svg';
import youtube from  '../../Components/Resource/ICONOSREDES/REDESSOCIALES/youtube.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import { GoogleLogout } from 'react-google-login';
import  history from '../../history'
import  './index.css' 

class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }


  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <a href="" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

export default class MobileMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
    
    };
    this.handleStateChange= this.handleStateChange.bind(this);
    this.handleTiendas = this.handleTiendas.bind(this);
  }

  handleStateChange (state) {
    
    this.props.handleMenuClose() 
  }
  handleTiendas(){
  
    window.scrollTo(0,0);
    window.open("https://tiendas.nutrisa.com","_blank")
  }



  menuEnlaces=()=>{
  
    history.push("/")
    window.scrollTo(0,0);
    this.props.handleMenuClose()
  }
 
   
    render () {
      class CustomToggle extends React.Component {
        constructor(props, context) {
          super(props, context);
  
          this.handleClick = this.handleClick.bind(this);
        }
  
        handleClick(e) {
          e.preventDefault();
  
          this.props.onClick(e);
        }
  
        render() {
          return (
            <a href="" onClick={this.handleClick}>
              {this.props.children}
            </a>
          );
        }
      }
      const usuario = JSON.parse(window.localStorage.getItem("userData"))
 
      
    
      return (
       
        <div id="outer-container-movil">
      
            <Menu 
            right
            isOpen={this.props.Open}
            customBurgerIcon={ false }
            disableOverlayClick = {() =>  {this.props.handleMenuClose()} }
            >

            
            {usuario ?
              <div style={{ outline:"none",display: "flex",flexDirection:"column" }}>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <img style={{ borderRadius: "50%", height: "55px", Width: "40px" }} src={usuario.imageUrl}></img>
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
   
                  <a style={{ whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",color: "#ffffff", fontWeight: "bold", padding: "0px" }} >{usuario.email}</a>
                  <a style={{ color: "#ffffff" }}>{usuario.nombre}</a>
                </div>

              </div>

              : false
            }
            <a  style={{width:"100%",height: "5%",marginLeft: "0px"}} onClick={()=> {this.menuEnlaces()}}  className="menu-item--smal">
              <img alt="conexión lenta"  style={{width: "100%", height:"10vw"}} src={LogoNutrisa}/>
            </a>
           
            <Link style={{fontFamily:"Gilroy",display:this.props.Skin ? 'none':'block'}} onClick={()=>{this.props.handleMenuClose();window.scrollTo(0,0)}} className="menu-item--smal"   to="/">INICIO</Link>
           
           <hr style={{display:this.props.Skin ? 'none':'block'}} className="lineas"/>
            <Link style={{fontFamily:"Gilroy",marginTop: "12%",display:this.props.Skin ? 'none':'block'}} onClick={()=> {this.props.handleMenuClose();window.scrollTo(0,0)}}className="menu-item--smal" to="/login">LOGIN</Link>
           <hr style={{display:this.props.Skin ? 'none':'block'}} className="lineas"/>
           
            <Link style={{fontFamily:"Gilroy",marginTop: "12%",display:this.props.Skin ? 'none':'block'}} onClick={()=> {this.props.handleMenuClose();window.scrollTo(0,0)}} className="menu-item--smal" to="/register">REGISTRO</Link>
            <hr style={{display:this.props.Skin ? 'none':'block'}} className="lineas"/>

            <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",marginTop:"5px"}}>
            <GoogleLogout
                  clientId="552941823838-h86vedreua9665gnod754e340kt1f0ri.apps.googleusercontent.com"
                  icon={true}
                  buttonText="Cerrar sesión"
                  onLogoutSuccess={()=>this.props.Logout() }
                  onFailure={()=>this.props.Logout()}
                >
                </GoogleLogout>
            </div>
  
            <div 
           className="menu-item--smal"
            style={{
            display:"flex",
            margin: "0%",
            position:"absolute",
            bottom:"10px",
            justifyContent: "space-between"
                                        
            }}
          >
                                            
             <img alt="conexión lenta"  onClick={(e)=>{this.props.handleMenuClose();window.open('https://www.facebook.com',' "_blank"') }} className="social-icon-movil"  src={facebook} />
             <img alt="conexión lenta"  onClick={(e)=>{this.props.handleMenuClose();window.open('https://www.instagram.com',' "_blank"') }}  className="social-icon-movil" src={instagram} />
             <img alt="conexión lenta"  onClick={(e)=>{this.props.handleMenuClose();window.open('https://twitter.com',' "_blank"') }} className="social-icon-movil"  src={twitter} />
             <img alt="conexión lenta"  onClick={(e)=>{this.props.handleMenuClose();window.open('https://www.youtube.com',' "_blank"') }}  className="social-icon-movil" src={youtube} />

          
          </div>
            </Menu>
            
      
   </div>
      
  
        
      );
    }
  }
import React, { } from 'react'
import { slide as Menu } from 'react-burger-menu'
import './index.css'
import MenuLateral from '../MenuLateral/index'
const BurgerMenu = () => {
    return (
        <Menu>
       <MenuLateral/>
      </Menu>
    )
}

export default BurgerMenu
import API from './config';

const pedidoService = {
    getAll: () => new Promise(
        (resolve, reject) => {
            var information = []
            API.get('salesorder/pedidos')
                .then(res => res.data)
                .then(data => {
                    console.log(data)
                    data.forEach(item => {
                       

                        var total = 0.0;
                        item.Header.orderPackaging.forEach(a => (total += parseFloat(a.weight)))



                        information.push({
                            'id': item.Header.id,
                            'status_id': item.Header.status.id,
                            'status': item.Header.status.name,
                            'no_pedido': item.Header.saleOrderReference,
                            'fecha_entrada': item.Header.orderCreatedDate,
                            'no_cajas': item.Header.orderPackaging.length,
                            'orderPackaging': item.Header.orderPackaging,
                            'peso': total.toFixed(3) ,
                            'inventario': item.Header.inventory.name,
                            'paqueteria': item.Header.shippingContact.name,
                            'detail': item.Header.Detail,
                            'customerName': item.Header.customerName,
                            'totalAmount': item.Header.totalAmount,
                            'sub_total': item.Header.ExtendedAmount,
                            'descuento': item.Header.Discount,
                            'impuestos': item.Header.TotalTaxes,
                            'costo_envio': item.Header.TotalShipping,
                            'guia': item.Header.ShippingTracking,
                            'url': item.Header.ShippingTracking.url
                        });

                    })


                    resolve(information)
                })
                .catch(error => reject(error))
        }
    ),

    actualizarConfirmacionPedido: (data) => new Promise(
        (resolve, reject) => {
        
            API.put(`salesorder/pedidos/${data.Header.id}`, data)
                .then(res => res.data)
                .then(data => resolve(data))
                .catch(error => reject(error))
        }
    )
}

export default pedidoService;
import API from './config';

const FirebaseTokenService = {
    postToken: (token) => new Promise(
        (resolve, reject ) => {
         
            API.post('/notification/registerToken', token)
            .then( res => res.data)
            .then(data => {
                console.log(data)
                resolve(data)
            })
            .catch( error => reject(error))
        }
    )

}

export default FirebaseTokenService;
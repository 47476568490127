import React, { Fragment } from 'react'
import "./styles/index.css";
import checkLogo from "./img/check.svg";
import searchlogo from "./img/search.svg";
import inventoryBalanceService from "../../Services/InventoryBalance.service";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Search from '../Search'

class Inventario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list_inventory: [],
      columns: [
        {
          dataField: 'ItemMaster.name',
          text: 'SKU',
          style: {
            width: '90px'
          }
        },
        {
          dataField: 'ItemMaster.description',
          text: 'NOMBRE DEL PRODUCTO',
          align: 'left',
          style: {
            width: '470px'
          }
        },
        {
          dataField: 'Quantity',
          text: 'CANTIDAD DISPONIBLE',
          style: {
            width: '200px'
          }
        },
        {
          dataField: 'LastsUpdated',
          text: 'FECHA',
          style: {
            width: '200px'
          }
        }
      ],

    }
  }
  componentDidMount() {
    this.getInvetoryBalance();

    setInterval( ()=> { 
      this.getInvetoryBalance();
      console.log('cada minuto llama a getInvetoryBalance() ')
    }, 60000)
  }

  getInvetoryBalance = () => {
    inventoryBalanceService
      .getAll()
      .then((inventory) => this.setState({ list_inventory: inventory }))
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  render() {
    const MySearch = (props) => {
      let input;
      const handleClick = () => {
        props.onSearch(input.value);
      };
      return (
        <div className="search">
          <input
            className="search-input"
            ref={n => input = n}
            type="text"
            onChange={handleClick}
            placeholder="Buscar"
          />
          <button className="search-btn" onClick={handleClick}><img src={searchlogo} width="18px" height="18px" /></button>
        </div>
      );
    };

    const selectRow = {
      mode: "checkbox",
      onSelect: this.handleOnSelect,
      selectionHeaderRenderer: ({ mode, checked, disabled }) => (
        <img src={checkLogo} alt="check" />
      ),
      selectionRenderer: ({ mode, checked, disabled }) => (
        <label className="container-checkmark">
          <input type={mode} />
          <span className="checkmark"></span>
        </label>
      ),
    };
    return (
      <>
        <div className="inventory-balance">

          <header className="title-inventory">
            balance de inventarios
          </header>

          <div className="table-responsive-sm">
            <div style={{height:'65vh',overflow:'auto',position: 'relative'}} className="InventoryTable">
              <ToolkitProvider
                keyField="id"
                data={this.state.list_inventory}
                columns={this.state.columns}
                search
              >
                {props => (
                  <Fragment>
                    <MySearch {...props.searchProps} />
                    <BootstrapTable
                      keyField="id"
                      /*selectRow={selectRow}*/
                      {...props.baseProps}
                    />
                  </Fragment>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Inventario;
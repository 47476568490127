import * as React from "react"

function CajaIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={27.62}
      height={27.621}
      viewBox="0 0 27.62 27.621"
      {...props}
    >
      <defs>
        <style>{".prefix__a{fill:#969393}"}</style>
      </defs>
      <path
        className="prefix__a"
        d="M11.155 12.291l2.379-1.577a.521.521 0 01.59 0l2.342 1.567V6.374h-5.311zM11.06 0H2.986L.33 5.311h9.843zM12.748 19.122H3.187v5.315h9.561zm-7.437 4.249H4.78a.531.531 0 110-1.062h.531a.531.531 0 110 1.062zm0-2.124H4.78a.531.531 0 010-1.063h.531a.531.531 0 110 1.063zm5.844 2.124H7.437a.531.531 0 110-1.062h3.718a.531.531 0 110 1.062zm0-2.124H7.437a.531.531 0 110-1.063h3.718a.531.531 0 010 1.063z"
      />
      <path
        className="prefix__a"
        d="M.003 27.621h27.62V6.572l-.1-.2h-9.994v6.905a.531.531 0 01-.828.441l-2.874-1.929-2.911 1.929a.521.521 0 01-.292.09.5.5 0 01-.249-.064.522.522 0 01-.281-.467V6.372H.003zm2.124-9.03a.531.531 0 01.532-.531h10.623a.532.532 0 01.532.531v6.374a.532.532 0 01-.532.532H2.659a.532.532 0 01-.532-.532zM16.371 5.311L15.484 0h-3.347l-.886 5.311zM27.109 5.311l-2.61-5.312h-7.937l.207 1.238.668 4.074z"
      />
    </svg>
  )
}

export default CajaIcon

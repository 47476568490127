import React from 'react';
import './css/header.css';

class Header extends React.Component {
    render() {
        return (
            <header className="font-weight-bold HeaderComponent">
                e-commerce nutrisa.
            </header>
        )
    }
}

export default Header;
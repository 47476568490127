import React from 'react'
import { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal'
import { FiMinus, FiPlus } from 'react-icons/fi';
import pedidoService from '../../Services/pedidos.service';
import shortageService from "../../Services/shortage.service";
import './css/PedidoIncompletoModal.css';
import ModalAlerta from '../ModalAlertas';
import Swal from 'sweetalert2'
import paloma from '../Pedidos/img/Trazado 42223.svg'
import { ClipLoader } from 'react-spinners';

class PedidoIncompletoModal extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.props.dataProducts.detail.forEach(e => {
            e.confirmedQuantity = e.Quantity

        });

        this.state = {
            show: false,
            pedido: this.props.dataProducts,
            list_shortage: [],
            loading: false,
            loadingClio: false
        };

        this.arreglo_shortages = [];
    }


    componentDidMount() {
        this.obtenerCombos();
    }

    obtenerCombos = () => {
        shortageService
            .getAll()
            .then((pedidos) => {
                this.setState({ list_shortage: pedidos })
            })
            .catch((error) => console.log(`Ocurrio un error: ${error}`));
    };


    mostrarModalAlerta = () => {
        this.setState({ showModalAlerta: true, tituloModal: 'El pedido se guardo correctamente' });
    };

    prevStep = (e) => {
        e.preventDefault();
        this.arreglo_shortages = [];
        this.props.prevStep();
    }

    aumentarCantidad(item) {
        console.log('AUmentando');
        var cantidad = parseInt(item.confirmedQuantity) + 1;

        if (cantidad <= parseInt(item.Quantity)) {
            item.confirmedQuantity = cantidad;
            this.setState({ pedido: this.state.pedido });
        }

    }

    disminuirCantidad(item) {
        console.log('Disminuyendo');
        console.log(item.confirmedQuantity);
        var cantidad = parseInt(item.confirmedQuantity) - 1;

        if (cantidad >= 0) {
            item.confirmedQuantity = cantidad;
            console.log(cantidad);
            this.setState({ pedido: this.state.pedido })
        }
    }

    handleMotivoChange(e, i) {

        this.state.pedido.detail[i].ShortReasonName = e.target.value;

    }
    handelShortageDescriptionChange(e, i) {

        this.state.pedido.detail[i].ShortageDescription = e.target.value;

    }


    actualizarInformacionPedido = () => {
        console.log('Va a actualizar')

        var valid = false;

        for (let i = 0; i < this.state.pedido.detail.length; i++) {
            var x = parseInt(this.state.pedido.detail[i].confirmedQuantity)
            var y = parseInt(this.state.pedido.detail[i].Quantity)
            if (x != y) {
                valid = true;
                break;
            }
        }

        if (!valid) {
            //PONER AQUI MENSAJE AL USUARIO
            Swal.fire({
                title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Es necesario cambiar la cantidad disponible para notificar faltante. </p> ",
                type: 'error',
                imageHeight: 100,
                showConfirmButton: true,
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#FD6920'
            });
            return
        }

        //sacar en un nuevo arreglo los elementos que deben de llevar una shortReasonName 
        this.arreglo_shortages = this.state.pedido.detail.filter(e => {
            if (e.confirmedQuantity < parseInt(e.Quantity)) {
                if (e.ShortReasonName === '') {
                    return e;
                }
            }
        });

        /**
         * Usando Array.some() se regresa si ShortReasonName es nulo si es true se muestra mensaje de error 
         * ya que no puede ir vació si la cantidad confirmada es menor que la cantidad solicitada.
         * Array.some() regresa true si al menos encuentra un elemento que cumpla con la condición hecha, si no encuentra nada
         * regresa false y se procede a hacer la actualización del pedido y la llamada del servicio.
         */
        const validarShortagesReason = this.arreglo_shortages.some(pedido => pedido.ShortReasonName === '');

        if (validarShortagesReason) {
            Swal.fire({
                title: 'Error al actualizar',
                text: 'Debes de indicar un motivo. Verificar',
                type: 'error',
                confirmButtonColor: 'red',
                confirmButtonText: ' Cerrar'
            });
            return
        } else {
            console.log('Hacer actualización')
            let arreglo_detalle = []

            this.state.pedido.detail.map(detail => {
                arreglo_detalle.push(
                    {
                        "Id": detail.id,
                        "ItemMasterID": detail.ItemMaster ?.name,
                        "confirmedQuantity": detail.confirmedQuantity,
                        "UnitPrice": detail.UnitPrice,
                        "Quantity": detail.Quantity,
                        "ExtendedAmount": detail.ExtendedAmount,
                        "MarketingProgram": detail.MarketingProgram,
                        "PercentageMarketingProgram": detail.PercentageMarketingProgram,
                        "AmountMarketingProgram": detail.AmountMarketingProgram,
                        "TaxBaseAmount": detail.TaxBaseAmount,
                        "IVATax": detail.IVATax,
                        "IEPSTax": detail.IEPSTax,
                        "TotalTaxes": detail.TotalTaxes,
                        "TotalAmount": detail.TotalAmount,
                        "ShortageReasonId": detail.ShortReasonName,
                        "ShortageDescription": detail.ShortageDescription
                    }
                )
            });

            let actualizarPedido = {
                "Header": {
                    "id": this.props.dataProducts.id,
                    "status": 31,
                    "OrderPackaging": [],
                    "Detail": arreglo_detalle
                }
            }

            console.log(actualizarPedido);
            //console.log(JSON.stringify(actualizarPedido));
            this.setState({ loadingClio: true })
            this.setState({ loading: true })
            pedidoService.actualizarConfirmacionPedido(actualizarPedido)
                .then(() => {
                    this.setState({ loading: false })
                    this.setState({ loadingClio: false })
                    Swal.fire({
                        title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Faltante notificado. </p> ",
                        imageUrl: paloma,
                        imageWidth: 300,
                        imageHeight: 100,
                        imageAlt: 'Custom image',
                        showConfirmButton: true,
                        confirmButtonText: 'Cerrar',
                        confirmButtonColor: '#FD6920'
                    }).then(res => {
                        if (res) {
                            this.props.closeModal();
                            this.props.updateRow();
                            this.props.closeModalResume();
                            this.props.resetData();
                        }
                    });
                })
                .catch((error) => {
                    this.setState({ loadingClio: false })
                    this.setState({ loading: false })
                    console.log(`Ah ocurrido un error: ${error}`)
                });
        }

    }


    render() {

        return (
            <Fragment>
                <Modal.Header >
                    <Modal.Title className="ml-3">Resumen - Pedido Incompleto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ marginLeft: '10px', color: 'gray' }}>Número de pedido: {this.state.pedido.no_pedido}</div><br></br>
                    <table className="table PedidoIncompletoModal__table" style={{ width: '75vw' }}>
                        <thead>
                            <tr>
                                <th style={{ width: '30vw' }}><div style={{ marginLeft: '40px' }}>Producto</div></th>
                                <th style={{ textAlign: 'center' }}>Cantidad requerida</th>
                                <th style={{ textAlign: 'center' }}>Cantidad disponible</th>
                                <th style={{ textAlign: 'center' }}>Menciona el motivo</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                this.state.pedido.detail.map((item, index) => {

                                    // eslint-disable-next-line no-unused-expressions
                                    if (item.ItemMaster.name != "085069" && item.ItemMaster.name != "085070" && item.ItemMaster.name != "085071")
                                        return <tr key={index}>
                                            <td style={{ color: '#939393', letterSpacing: '0.3px' }}>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <img src={item.ItemMaster.image} alt="logo_producto "
                                                            style={{ width: '100px', height: '100px' }} />
                                                    </div>
                                                    <div className="col-9 align-self-center">
                                                    SKU: {item.ItemMaster.name}<br/>
                                                        {item.ItemMaster.description}
                                                    </div>
                                                </div>
                                            </td>

                                            <td style={{ verticalAlign: 'middle', textAlign: 'center', color: '#FF6900', fontFamily: 'Gilroy SemiBold', fontSize: '1.5em' }}>
                                                {parseInt(item.Quantity)}
                                            </td>

                                            <td style={{ verticalAlign: 'middle', textAlign: 'center', color: '#FF6900', fontFamily: 'Gilroy SemiBold', fontSize: '1.5em' }} >
                                                <div className="PedidoIncompletoModal__count">
                                                    <span className="minusplus" onClick={() => this.disminuirCantidad(item)} style={{ marginRight: '20px' }}><FiMinus /></span>
                                                    <span style={{ marginTop: '1px', paddingTop: '1px' }}>{parseInt(item.confirmedQuantity)}</span>
                                                    <span className="minusplus" onClick={() => this.aumentarCantidad(item)} style={{ marginLeft: '20px' }}><FiPlus /></span>
                                                </div>
                                            </td>


                                            <td style={{ verticalAlign: 'middle', textAlign: 'center', color: '#535353', fontFamily: 'Gilroy SemiBold', fontSize: '1.5em' }} >

                                                {
                                                    parseInt(item.Quantity) === parseInt(item.confirmedQuantity)
                                                        ?
                                                        <></>
                                                        :
                                                        <div className="motivocontainer">
                                                            <select onChange={e => this.handleMotivoChange(e, index)} className="combomotivo">
                                                                <option>Selecciona uno</option>
                                                                {this.state.list_shortage.map((e, i) =>
                                                                    <option key={i} value={e.Header.id}>{e.Header.ShortReasonName}</option>
                                                                )}
                                                            </select>
                                                            <textarea onChange={e => this.handelShortageDescriptionChange(e, index)} className="textmotivo" placeholder="Escribe ..."></textarea>
                                                        </div>
                                                }

                                            </td>

                                        </tr>
                                }
                                )
                            }
                        </tbody>
                    </table>

                    {/* <div className="text-right">
                        <label className="PedidoIncompletoModal__label_total"> Total
                            <span className="PedidoIncompletoModal__price_total">
                                {this.state.pedido.totalAmount}
                            </span>
                        </label>
                    </div> */}


                </Modal.Body>
                <Modal.Footer className="border-0">

                </Modal.Footer>
                <div className="bottomm">

                    {!this.state.loading && <button className="btnBack" variant="outline-warning" size="lg" onClick={this.prevStep}>
                        &lt; REGRESAR
                </button>}

                    {this.state.loading && <button className="btnBack" variant="outline-warning" size="lg" disabled="true">
                        &lt; REGRESAR
                </button>}

                    {!this.state.loading && <button className="btnBorder btnCont" variant="outline-success" size="lg" onClick={this.actualizarInformacionPedido}>
                        Notificar Faltante</button>}
                    {this.state.loading && <button className="btnBorder btnDisabled" variant="outline-success" size="lg">
                        Notificar Faltante</button>}


                </div>
                <div className="spinner">
                    <ClipLoader
                        sizeUnit={"px"}
                        size={80}
                        color={'#FF6900'}
                        loading={this.state.loadingClio}
                    />
                </div>

            </Fragment>
        )
    }
}

export default PedidoIncompletoModal;
import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './index.css';

export default class ModalDetailCajas extends Component {

    render() {
        console.log("")
        console.log(this.props.detallesCajas)
        
        return (
            <Modal show={this.props.showModal}>
                <Modal.Header >
                    <Modal.Title style={{marginLeft:'10%'}}>¿Cuál es el peso de las cajas?</Modal.Title>
                    <span className="mr-3-mb-3"><img onClick={this.props.closeModal} style={{ height: "18px", marginbuttom: "3px" }} src="/img/closebtn.svg" /></span>
                </Modal.Header>
                <Modal.Body className="border-0">
                    <div style={{display:'flex', justifyContent:"space-around"}}>
                        
                            <span style={{marginTop:'2px'}}> Número de pedido {this.props.detallesCajas.no_pedido}</span>
                        
                        <div className="total-container-cajas">
                            <div>
                                <span style={{fontSize: '1.2em'}}>TOTAL : </span>

                                <span style={{ color: "#FF6400", fontSize: '1.2em' }}> 
                                    { this.props.detallesCajas.peso} KG
                                </span>
                                
                            </div>
                        </div>
                    </div>
                    
                    <Container className="boxweight-container">
                        <table className="table tableBox ">
                            <thead style={{textAlign:'center'}}>
                                <th scope="col">CAJA</th>
                                <th scope="col">TAMAÑO</th>
                                <th scope="col">PESO KG</th>
                            </thead>

                            <tbody>
                                {
                                    this.props.detallesCajas.orderPackaging.map( (item, index )=>
                                        <tr key={index}>
                                            <td  style={{textAlign:'center'}}>
                                                <img src="/img/boxes/boxActive.svg" alt="" 
                                                className={item.packagingType.shortName} style={{color: '#FF5D00'}}/>
                                            </td>

                                            <td style={{color:'gray', textAlign:'center', verticalAlign:'middle'}}>
                                                {item.packagingType.name + ' ' + item.packagingType.shortName} 
                                            </td>

                                            <td  style={{textAlign:'center', verticalAlign:'middle'}}>
                                                {item.weight}
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                        
                    </Container>

                    

                    
                </Modal.Body>
            </Modal>
        )
    }
}
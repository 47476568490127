import React, { Component } from 'react';
import PropTypes from "prop-types";
import "./Stepper.css";
import index from '../PurchaseSumModal';
import Barcode from "react-hooks-barcode";
import ReactToPrint from 'react-to-print';
import logo from './img/LOGO-NUTRISA-ngro.png'
import ModalImprimirGuia from '../ModalImprimirGuia/index'
import Swal from 'sweetalert2'
import info from '../ScannerModal/img/256px-Info_icon.svg.png'
import ConfirmarGuiaService from '../../Services/confirmarGuia.service';
import paloma from './img/Trazado 42223.svg'
import { ClipLoader } from 'react-spinners';

export default class Stepper extends Component {
    constructor() {
        super();
        this.state = {
            steps: [],
            showModalGuia: false,
            loading: false
        };
        this.handleModalGuia = this.handleModalGuia.bind(this);
        this.handleGuiaConfirmar = this.handleGuiaConfirmar.bind(this);
        this.valido = false;
        this.rowSelected = []
        this.statusValidTicket = null;
    }



    //metodo para validar la impresion de una guía.
    handleValidGuia() {

        

        //valida que se haya seleccionado al menos un registro
        if (this.rowSelected.length > 0) {
            this.valido=false;
            this.rowSelected.map((element, index) => {
                if(element.url != ''){
                    if (element.status === "Imprimir Guía" ) {
                        this.valido = true
                    }
                    if (this.valido) {
                        this.handleModalGuia()
                    } else {
                        Swal.fire({
                            title: "Solo puedes imprimir guías de pedidos 'Imprimir Guía'",
                            imageUrl: info,
                            imageWidth: 100,
                            imageHeight: 100,
                            imageAlt: 'Custom image',
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
        
                }else{
                    
                    this.valido = false
                    Swal.fire({
                        title: "Ha ocurrido un error al generar guía, intenta más tarde.",
                        imageUrl: info,
                        imageWidth: 100,
                        imageHeight: 100,
                        imageAlt: 'Custom image',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
               
            })
           

        } else {
            this.props.abrirModalBuscarGuiaImprimir();
        }
    }

    handleModalGuia() {
        this.setState({
            showModalGuia: !this.state.showModalGuia
        })

    }

    confirmarPedido = (id) => {
        console.log(id);
        console.log('Confirmar pedido');
    }

    //metodo para validar la confirmación de una guía
    handleGuiaConfirmar() {
        console.log('Guía a confirmar ')
        console.log(this.props.rowSelected);

        //validar que se haya seleccionado un pedido en el grid
        if (this.props.rowSelected.length > 0) {

            //validar que el estatus del pedido sea igual a 'Guía Impresa'
            if (this.props.rowSelected[0].status === "Guía Impresa") {
                /*this.setState({
                    showModalGuia:!this.state.showModalGuia
                });*/

                //Mandar a llamar el servicio de confirmación de una guía
                //cambiar estado de Loading
                this.setState({loading: true })
                ConfirmarGuiaService.postConfirmarGuia(this.props.rowSelected[0].id)
                    .then(() => {
                        this.props.obtenerPedidos();
                        this.props.clearDataSelected()
                        Swal.fire({
                            title: 'Guías confirmadas',
                            imageUrl: paloma,
                            imageWidth: 300,
                            imageHeight: 100,
                            imageAlt: 'Custom image',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        this.setState({ loading: false });
                
                    })
                    .catch(error => {
                        console.log(`Ha ocurrido un error: ${error}`) 
                        this.setState({ loading: false });
                        Swal.fire({
                            title: 'No se puede confirmar por el momento.',
                            type: 'error',
                            confirmButtonText: 'Cerrar',
                            confirmButtonColor: 'red'

                        });
                    });

            }else{
                this.setState({loading: false})
                Swal.fire({
                    title: "Solo puede confirmar guías con estatus de 'guía impresa' ",
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        } else {
            this.props.abrirModalBuscarGuiaConfirmar();
        }


    }

  

    handlePedido = () => {

        if(this.props.rowSelected.length === 1 ){
            this.props.handleConfirmarPedidoGrid()
        }else{
            this.props.handleModalScanner();
            this.props.handleGuia(false)
        }
     
    }

    componentDidMount() {
        const { steps, currentStepNumber } = this.props;
        const stepsState = steps.map((step, index) => {
            const stepObj = {};
            stepObj.id = step.id;
            stepObj.name = step.name;
            stepObj.image = step.image;
            stepObj.imageActive = step.imageActive;
            stepObj.completed = false;
            stepObj.highlighted = index === 1 ? true : false;
            stepObj.selected = index === 1 ? true : false;
            return stepObj;
        });

        const currentSteps = this.updateStep(currentStepNumber - 1, stepsState);
        this.rowSelected = this.props.rowSelected
        this.statusValidTicket = this.props.statusValidTicket;
        this.setState({
            steps: currentSteps
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rowSelected.length !== this.props.rowSelected.length) {
            console.log("update")
            this.rowSelected = this.props.rowSelected
        }
        if (prevProps.currentStepNumber !== this.props.currentStepNumber) {
            const { steps } = this.state;
            const currentSteps = this.updateStep(this.props.currentStepNumber - 1, steps);

            this.setState({
                steps: currentSteps
            });
        }

        
        
        if( prevProps.posicion_stepper !== this.props.posicion_stepper){
            //console.error(` prevProps de posicion_stepper: ${prevProps.posicion_stepper}`);
            //console.error(` this.props.posicion_stepper de posicion_stepper: ${this.props.posicion_stepper}`);

            if(this.props.rowSelected.length){
                const { steps } = this.state;
                let currentSteps = []
                switch (this.props.rowSelected[0].status) {

                    case 'Por Confirmar':
                        currentSteps = this.updateStep( 0, steps);
                        this.setState({ steps: currentSteps });
                        break;

                    case 'Confirmado':
                        currentSteps = this.updateStep( 1, steps, 'Confirmado');
                        this.setState({ steps: currentSteps });
                        break;

                    case 'Cobrado':
                        currentSteps = this.updateStep( 1, steps, 'Cobrado');
                        this.setState({ steps: currentSteps });
                        break;
                    
                    case 'Imprimir Guía':
                        currentSteps = this.updateStep( 2, steps);
                        this.setState({ steps: currentSteps });
                        break;

                    case 'Guía Impresa':
                        currentSteps = this.updateStep( 3, steps);
                        this.setState({ steps: currentSteps });
                        break;
                    default:
                        console.log(`Pedido seleccionado con status diferente: ${this.props.rowSelected[0].status}`)
                        break;
                }
                
            }else{
                const { steps } = this.state;
                steps.map( a => {
                    a.highlighted = false;
                    a.completed = false;
                    a.selected = false;
                    return a
                })
                this.setState({ steps: steps })
            }
        }

            //console.warn(` prevProps de posicion_stepper_actualizado: ${prevProps.posicion_stepper_actualizado}`);
            //console.warn(` this.props.posicion_stepper_actualizado: ${this.props.posicion_stepper_actualizado}`);
        
            //este if funciona para cuando se hace una actualizacion a algun registro pasar en automatico
        //a la siguiente opcion del stpper
        if(prevProps.posicion_stepper_actualizado !== this.props.posicion_stepper_actualizado){
            if(this.props.rowSelected.length){
                const { steps } = this.state;
                let currentSteps = []
                switch (this.props.rowSelected[0].status) {

                    case 'Por Confirmar':
                        currentSteps = this.updateStep( 1, steps);
                        this.setState({ steps: currentSteps });
                        break;
                    
                    case 'Imprimir Guía':
                        currentSteps = this.updateStep( 2, steps);
                        this.setState({ steps: currentSteps });
                        break;

                    case 'Guía Impresa':
                        currentSteps = this.updateStep( 3, steps);
                        this.setState({ steps: currentSteps });
                        break;
                    default:
                        console.log(`Pedido seleccionado con status diferente: ${this.props.rowSelected[0].status}`)
                        break;
                }
                
            }else{
                const { steps } = this.state;
                steps.map( a => {
                    a.highlighted = false;
                    a.completed = false;
                    a.selected = false;
                    return a
                })
                this.setState({ steps: steps })
            }
        }
    }

    updateStep(stepNumber, steps, estatus) {
        const newSteps = [...steps];
        let stepCounter = 0;
        while (stepCounter < newSteps.length) {
            //step actual
            if (stepCounter === stepNumber) {
                //validar y colocar completed en true si es estatus es alguno de los siguientes
                if(estatus === 'Cobrado' || estatus === 'Confirmado'){
                    newSteps[stepCounter] = {
                        ...newSteps[stepCounter],
                        highlighted: true,
                        selected: true,
                        completed: true
                    };
                    stepCounter++;
                }else{
                    newSteps[stepCounter] = {
                        ...newSteps[stepCounter],
                        highlighted: true,
                        selected: true,
                        completed: false
                    };
                    stepCounter++;
                }
            }
            // step pasado
            else if (stepCounter < stepNumber) {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: true,
                    selected: true,
                    completed: true
                };
                stepCounter++;
            }
            //future step
            else {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: false,
                    selected: false,
                    completed: false
                };
                stepCounter++;
            }
        }
        return newSteps;
    }

    handleValidTicket = () => {
        this.valido = false
        
        this.rowSelected.map((element, index) => {
            console.log(element.status)
            console.log(this.statusValidTicket)
            if (element.status === this.statusValidTicket) {

                this.valido = true
            }
        })

        if (this.valido) {
            return this.componentRef
        } else {
            Swal.fire({
                title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Solo puedes imprimir tickets de pedidos 'Por Confirmar' </p> ",
                type: 'error',
                imageHeight: 100,
                showConfirmButton: true,
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#FD6920'
            });
        }

    }



    render() {

        const config = {
            background: "#f5f5f5",
            marginTop: "20px",
            marginBottom: "20px",
            fontOptions: "italic",
            width: 2
        };
        const { steps } = this.state;
        const stepsDisplay = steps.map((step, index) => {

            return (

                <div className="step-wrapper" key={index} onClick={() => {
                    if (step.id === 2) {
                        this.handlePedido();
                    }  else if (step.id === 1) {
                        //do nothig
                    } else if (step.id === 4) {
                        if (!this.state.loading){
                            this.handleGuiaConfirmar();
                        }
                    } else { 
                        this.handleValidGuia();
                    }
                    }}>
                    <div className="step-number">{step.completed ? <span>&#10003; </span> : ""}</div>
                    {step.id === 1 ?

                        <ReactToPrint
                            ref={(control) => { this.page = control }}
                            documentTitle="Ticket"
                            trigger={() => {
                                return <div style={{ pointerEvents: this.props.selectPedido ? "" : "none" }} className="step-img" onClick={e => this.props.click(index + 1)}  ><img src={step.selected ? step.imageActive : step.image} alt="" /></div>;
                            }}
                            content={() => this.handleValidTicket()}
                        />

                        :
                        <div className="step-img" onClick={e => this.props.click(index + 1)}  ><img src={step.selected ? step.imageActive : step.image} alt="" /></div>
                    }

                    <div className={`step-name ${step.highlighted && "step-name-active"}`}>{step.name}</div>
                    <div className={index !== steps.length - 1 ? `divider-line divider-line-${steps.length}` : ""} />

                </div>
            )
        })
        return <>
        <div className="stepper-wrapper" >{stepsDisplay}
           

        {/** loading que se ejecuta cunado se hace una petición al servicio */}
        <div className="spinner">   
            <ClipLoader
                sizeUnit={"px"}
                size={80}
                color={'#FF6900'}
                loading={this.state.loading}
            />
        </div> 

 
            <div style={{ display: "none" }}>
                <div className="my-page" ref={el => (this.componentRef = el)} >
                    {this.props.rowSelected.length > 0 ? this.props.rowSelected.map((element, index) => {

                        if (element.status === this.statusValidTicket) {
                            return (
                                <>
                                    <h3></h3>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop: "10px" }}>
                                        <img style={{ width: "168px" }} src={logo} /><br />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>

                                        NUTRISA S.A. de C.V.<br />
                                        CALZADA SAN BARTOLO NAUCALPAN 360 COL. ARGENTINA PONIENTE MIGUEL HIDALGO CIUDAD DE MEXICO 11230<br />

                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                                        E-COMMERCE<br />
                                    </div>
                                    <div style={{ display: "flex", marginTop: "20px" }} >
                                        <div style={{ width: "100%" }}>
                                            FECHA:{element.fecha_entrada}<br />
                                            CLIENTE:  {element.customerName}
                                        </div>
                                        {/* <dv style={{ width: "50%" }}>
                                            HORA:{"18:04:53"}<br/>
                                            ART. {element.detail.length}<br />
                                        </dv> */}
                                    </div>
                                    <table style={{ width: "100%", marginTop: "20px" }} >
                                        <tr>
                                            <th style={{ width: "50%" }}>ART.</th>
                                            <th>CANT.</th>
                                            {/* <th>UNIT.</th> */}
                                            <th>TOTAL</th>
                                        </tr>
                                        {element.detail.map((element, index) => {
                                            if (element.ItemMaster.name !== '085069' && element.ItemMaster.name !== '085070' && element.ItemMaster.name !== '085071') {
                                                return (
                                                    <tr style={{ marginTop: "20px" }}>
                                                        <td>
                                                            <div style={{ marginTop: "15px" }}>
                                                                {element.ItemMaster.name}<br />
                                                                {element.ItemMaster.description}
                                                            </div>

                                                        </td>
                                                        <td style={{ textAlign: "center" }}>{element.Quantity}</td>
                                                        {/* <td style={{textAlign:"center"}} >{element.UnitPrice}</td> */}
                                                        <td style={{ textAlign: "center" }} >{element.ExtendedAmount}</td>
                                                    </tr>
                                                )
                                            }

                                        })}
                                    </table>

                                    <div style={{ display: "flex", marginTop: "20px" }} >
                                        <div style={{ width: "50%" }}>
                                            Subtotal: <br />
                                            Descuentos:<br />
                                            Impuestos:<br />
                                            Costo de envío:<br /><br />

                                            TOTAL
                                        </div>
                                        <div style={{ textAlign: "end", width: "50%" }}>
                                            {element.sub_total}<br />
                                            {element.descuento}<br />
                                            {element.impuestos}<br />
                                            {element.costo_envio}<br /><br />
                                            {element.totalAmount}
                                        </div>
                                    </div>


                                    <hr style={{ borderTop: "3px dashed rgba(0,0,0)" }}></hr>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "cennter" }}>
                                        <Barcode  {...config} value={element.no_pedido} />
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop: "px", marginBottom: "75px" }}>
                                        GRACIAS POR SU COMPRA<br />
                                        www.nutrisa.com
                                    </div>
                                </>
                            )
                        }
                    })
                        :
                        ""
                    }
                </div>
            </div>
        </div>

        <ModalImprimirGuia
                obtenerPedidos={this.props.obtenerPedidos}
                handleModalGuia={this.handleModalGuia}
                showModalGuia={this.state.showModalGuia}
                rowSelected={this.rowSelected}
            />

        <ModalImprimirGuia 
            obtenerPedidos={this.props.obtenerPedidos}
            handleModalGuia={this.handleModalGuia}
            showModalGuia={this.state.showModalGuia}
            rowSelected={this.props.rowSelected}
        />
        </>
    }
}

Stepper.propTypes = {
    steps: PropTypes.array.isRequired
};

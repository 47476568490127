import API from './config';

const historialService = {
    getAll: () => new Promise(
        (resolve, reject ) => {
            var historial_information = []
            API.get('salesorder/pedidos/historial')
            .then( res => res.data)
            .then(data => {
                console.log(data)

                data.forEach( item => {
                    
                    let total = 0.0;
                    item.Header.orderPackaging.forEach( a => (total += parseFloat(a.weight)) )
                    
                    let total_articulos = 0;
                    item.Header.Detail.forEach( b => (total_articulos += parseInt(b.Quantity)) )
                    
                    historial_information.push({
                        'id': item.Header.id,
                        'status': item.Header.status.name,
                        'no_pedido': item.Header.saleOrderReference,    
                        'fecha_entrada': item.Header.orderCreatedDate,  
                        'no_cajas': item.Header.orderPackaging.length,
                        'peso': total.toFixed(3),
                        'articulos': total_articulos,          
                        'paqueteria': item.Header.shippingContact.name,
                        'guia': item.Header.ShippingTracking.shippingTracking,
                        'detail': item.Header.Detail,
                        'orderPackaging': item.Header.orderPackaging,
                    });

                })

                resolve(historial_information)
            })
            .catch( error => reject(error))
        }
    )

}

export default historialService;
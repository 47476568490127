import API from './config';

const InventoryBalanceService = {

    getAll: () => new Promise(
        (resolve, reject ) => {
            API.get('inventorybalance/pedidos/inventorybalance')
            .then( res => res.data)
            .then(data => {
                console.log(data)
                data.map( e => {
                    let fecha_servidor = e.LastsUpdated;
                    let solamente_fecha = fecha_servidor.slice(0, 10);

                    let hora = new Date(e.LastsUpdated);

                    e.LastsUpdated = solamente_fecha + ' ' + hora.toLocaleTimeString();

                    return e
    
                });
                
                resolve(data)
            })
            .catch( error => reject(error))
        }
    )
}

export default InventoryBalanceService;

/**
 * 
 * () => {
        var date = new Date(item.Header.orderCreatedDate)
        return date.toLocaleString()
    },  

    LastsUpdated
 */
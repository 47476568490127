import React from 'react';
import searchlogo from "./img/search.svg";
import Scanicon from "./img/scan.svg";
import './search.css';
const SearchComponent = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };
   
    return (
      <div className="search">
        <input
          className="search-input"
          ref={ n => input = n }
          type="text"
          onChange={handleClick}
          placeholder="Buscar"
        />
        <button className="search-btn text-center" onClick={ handleClick }>
            <img src={searchlogo} width="18px" height="17px"/>
        </button>
        {/*<button className="scan-btn"><img src={Scanicon}  width="30px" height="30px"/></button>*/}
      </div>
    );
};
export default SearchComponent;
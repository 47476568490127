import React,{PureComponent} from 'react'
import Terminos from '../TerminosModal/index'
import AvisoDePrivacidada from '../Avisoprivacidadmodal/index'
import { Icon } from 'react-icons-kit'
import { facebook2 } from 'react-icons-kit/icomoon/facebook2'
import { whatsapp } from 'react-icons-kit/icomoon/whatsapp'
import { twitter } from 'react-icons-kit/icomoon/twitter'
import { instagram } from 'react-icons-kit/icomoon/instagram'
import './index.css'
export default class Footer extends PureComponent{
    constructor(){
        super()
        this.state={

        }
        this.handleAvisoModal=this.handleAvisoModal.bind(this)
        this.handleModal=this.handleModal.bind(this)
    }
    handleModal(){
        this.setState({
            modal: !this.state.modal
        })
    }
  
    handleAvisoModal() {
        this.setState({
            avisomodal: !this.state.avisomodal
        })
    }
    render() {
        return (
            <>
                <div className="footer ">
                    <div className="footer-isq">
                            <img className="img-fluid" src="http://www.ajolotius.com/images/marcas/nutrisanuevo.png"></img>
                    </div>
                    <div className="footer-der">
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget finibus augue. Vestibulum vitae mollis urna. Morbi eleifend,
                         arcu sit amet tincidunt suscipit, nisi libero sollicitudin velit, a varius neque est eget diam. Donec est neque, sollicitudin 
                         sed viverra vel, tempus et nunc. 
                        </p>
                        <div style={{display:"flex",justifyContent:"space-evenly"}}>
                            < Icon  size = { 30 }  icon = { facebook2 } />
                            < Icon  size = { 30 }  icon = { twitter } />
                            < Icon  size = { 30 }  icon = { instagram } />
                            < Icon  size = { 30 }  icon = { whatsapp } />
                        </div>
                    <u style={{cursor:"pointer"}} onMouseDown={() => this.handleModal()}>Términos y condiciones </u> - <u style={{cursor:"pointer"}} onMouseDown={() => this.handleAvisoModal()}>Aviso de privacidad</u>
                    </div>
                </div>

                <Terminos
                    modal={this.state.modal}
                    handleModal={() => this.handleModal()}
                />
                <AvisoDePrivacidada
                    modal={this.state.avisomodal}
                    handleModal={() => this.handleAvisoModal()}
                />
            </>
        )
    }
}
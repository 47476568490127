import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { ModalAlertaError } from '../ModalAlertas/alertasError';
import Swal from 'sweetalert2'


export default class Boxes extends Component {
    constructor() {
        super()
        this.state = {
            
        }
    }
    continue = e => {
        e.preventDefault();
        
        //Obtiene el total de cajas que ha seleccionado el usuario.
        var siguienteModal = 0;
        
        this.props.boxes.forEach(a => siguienteModal += a.items.length)

        if (siguienteModal) {

            //validar que no haya seleccionado un máximo de 5 cajas por pedido.
            if(siguienteModal <= 5){
                this.props.nextStep();
            }else{
                Swal.fire({
                    title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Ocurrió un error al enviar la información </p> ",
                    text: "El máximo de cajas por pedido es de cinco. Verificar",
                    showConfirmButton: true,
                    type: 'error',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#FD6920'
                });
            }
            
        } else {
            Swal.fire({
                title: "<p style='color: #969393; font-family: 'Gilroy-SemiBold'; > Ocurrió un error al enviar la información </p> ",
                html: " <hr style=' background-color: #FF6900; size: '90px'; ' />  <p style='color: #969393;'> Debes de seleccionar al menos una caja para poder continuar </p> ",
                showConfirmButton: true,
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#FD6920'
            });
        }

    }
    prevStep = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    close = e => {
        e.preventDefault();
        this.props.closeModal();
    }

    render() {
        return (
            <div>
                <Modal.Header >
                    <Modal.Title className="ml-3">¿Cuántas cajas ocupaste?</Modal.Title>
                    <span className="mr-3-mb-3"><img onClick={this.close} style={{ height: "18px", marginbuttom: "3px" }} src="/img/closebtn.svg" /></span>
                </Modal.Header>
                <Modal.Body className="border-0">
                    <Container className="boxes">
                        <Row>
                            {this.props.boxes.map(box => (
                                <Col md={4} >
                                    <div className="box1" >
                                        <img onClick={() => this.props.addItem(box.size)} src={box.items.length >= 1 ? "/img/boxes/boxActive.svg" : "/img/boxes/box.svg"} className={box.size} alt="box1" />
                                        <p>{box.name}</p>
                                        <div className='ctrl'>
                                            <div className='ctrl__button ctrl__button--decrement' onClick={() => this.props.removeItem(box.size)}>&ndash;</div>
                                            <div className='ctrl__counter'>
                                                <input className='ctrl__counter-input' maxLength='10' type='number' defaultValue='0' />
                                                <div className='ctrl__counter-num'>{box.items.length}</div>
                                            </div>
                                            <div className='ctrl__button ctrl__button--increment' onClick={() => this.props.addItem(box.size)}>+</div>
                                        </div>
                                    </div>
                                </Col>
                            ))}

                        </Row>
                    </Container>
                </Modal.Body>
              <Modal.Footer className="border-0">
              
              </Modal.Footer>
              <div className="bottomm">
              <button className="btnBack" variant="outline-warning" size="lg" onClick={this.prevStep}>
               &lt; REGRESAR
                </button>

                <button className="btnBorder btnCont" variant="outline-success" size="lg" onClick={this.continue}>
                 Continuar
                </button>
              </div>
            </div>
        )
    }
}

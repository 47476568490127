import API from './config';

const loginService = {
    verify: (data) => new Promise(
        (resolve, reject) => {
            API.post('auth/google/verify/', data)
                .then(res => res.data)
                .then(data => {
                    resolve(data)
                })
                .catch(error => reject(error))
        }
    ),
}

export default loginService;
import API from './config';

const CancelListService = {
    getAll: () => new Promise(
        (resolve, reject ) => {
            API.get('salesorder/razonesCancelar')
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    )
}

export default CancelListService;

import React, { } from 'react'
import styles from './index.module.css'
import nutrisa from './img/Grupo 4726.svg'

import BurgerMenu from '../BurgerMenu/index'
import LogOut from "../LogOut";
import Notifications from '../Notifications';
import EngranePermiso from '../EngranePermiso/index';
const Menu = () => {
    return (
        <div className={styles.cont}>
        {/* <EngranePermiso/> */}
            <BurgerMenu/>
                E-COMMERCE
            <img style={{marginLeft:"1%"}} src={nutrisa} />
            <Notifications/>
            <LogOut/>
        </div>
    )
}
export default Menu
import React from "react";
import "./styles/menuLateral.css";
import abejaNutrisa from "./img/abeja.svg";
import inventario from "./img/inventario.svg";
import pedido from "./img/pedido.svg";
import historial from "./img/historial.svg";
import error_pedidos from './img/error_pedidos.svg';
import paqueteria from './img/paqueteria.svg';
import incompleto from './img/incompletos.svg';
import cancelar from './img/cancelarPedidos.svg';
import cancelados from './img/pedidosCancelados.svg';
import porSurtir from './img/pedidosPorSurtir.svg';
import { Link, withRouter } from "react-router-dom";

import { FiPackage } from 'react-icons/fi';


class MenuLateral extends React.Component {

  render() {
    return (
      <div style={{minWidth:'80px', maxWidth:'80px',marginTop:'28px'}} className="bg-light " id="sidebar-wrapper">
        <div className="nav nav-pills nav-flush flex-column mb-auto" style={{position:'relative'}}>
          {/* <a href="/" className="nav-link active" style={{ height: "100px"}}>
            <img
              src={abejaNutrisa}
              alt="abeja logo"
              style={{ width: "48px", marginTop: "15px" }}
            />
          </a> */}

          {/* <Link to="/pedidos" className="nav-link" style={{marginTop:'23px'}}> */}

          <Link to="/pedidos" className="nav-link" style={{marginTop: '0.9vw',}}>
            <img className={this.props.location.pathname === '/pedidos' || this.props.location.pathname === '/' ?"":"imgs " } src={pedido} alt="pedidos logo" />
            <div className="b-example-divider mt-1" ></div>
            {(this.props.location.pathname === '/pedidos' || this.props.location.pathname === '/') && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'4px'}}>Pedidos</div>}
            {this.props.location.pathname != '/pedidos' && this.props.location.pathname != '/' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'20px'}}></div>}
          </Link>
          
          <Link to="/seguimiento-paqueterias" className="nav-link" >
            <img className={this.props.location.pathname === '/seguimiento-paqueterias' ? "":"imgs" } src={paqueteria} alt="seguimiento logo" />
            <div  className="b-example-divider mt-1"></div>
            {this.props.location.pathname === '/seguimiento-paqueterias' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'4px', marginLeft:'-2px'}}>Seguimiento</div>}
            {this.props.location.pathname != '/seguimiento-paqueterias' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'20px'}}></div>}
          </Link>

          {/** pedidos incompletos realizar el componente. 
          <Link to="/pedidos-incompleto" className="nav-link" >
            <img className={this.props.location.pathname === '/pedidos-incompleto' ? "":"imgs"} src={incompleto} alt="error logo" />
            <div  className="b-example-divider mt-1"></div>
            {this.props.location.pathname === '/pedidos-incompleto' && <div style={{ color: "white", fontSize: "9.3px", marginBottom:'4px', marginLeft:'-2px'}}>Incompleto</div>}
            {this.props.location.pathname != '/pedidos-incompleto' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'20px'}}></div>}
          </Link>
*/}
          <Link to="/inventario" className="nav-link" style={{marginTop: '0.2vw', paddingRight:'9px'}}>
            <img className={this.props.location.pathname === '/inventario' ? "":"imgs"}  src={inventario} alt="inventario logo" />
            <div  className="b-example-divider mt-1"></div>
            {this.props.location.pathname === '/inventario' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'4px', marginLeft:'-4px'}}><span style={{paddingLeft:'2px'}}>Inventarios</span></div>}
            {this.props.location.pathname != '/inventario' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'20px'}}></div>}
          </Link>

          <Link to="/pedidos-error" className="nav-link" >
            <img className={this.props.location.pathname === '/pedidos-error' ? "":"imgs"} src={error_pedidos} alt="error logo" />
            <div  className="b-example-divider mt-1"></div>
            {this.props.location.pathname === '/pedidos-error' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'4px', marginLeft:'-2px'}}>Pedidos <br/> con error</div>}
            {this.props.location.pathname != '/pedidos-error' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'20px'}}></div>}
          </Link>

          <Link to="/historial" className="nav-link" >
            <img className={this.props.location.pathname === '/historial' ? "":"imgs"} src={historial} alt="historial logo" />
            <div  className="b-example-divider mt-1"></div>
            {this.props.location.pathname === '/historial' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'4px', marginLeft:'-2px'}}>Historial</div>}
            {this.props.location.pathname != '/historial' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'20px'}}></div>}
          </Link>
          
          <Link to="/cancelar-pedido" className="nav-link" >
            <img className={this.props.location.pathname === '/cancelar-pedido' ? "":"imgs"} src={cancelar} alt="cancelaciones logo" />
            <div  className="b-example-divider mt-1"></div>
            {this.props.location.pathname === '/cancelar-pedido' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'4px', marginLeft:'-7px', textAlign:'center'}}>Cancelar <br/> pedidos</div>}
            {this.props.location.pathname != '/cancelar-pedido' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'20px'}}></div>}
          </Link>

          <Link to="/pedidos-cancelados" className="nav-link" >
            <img className="imgs imgbs" src={cancelados} alt="cancelaciones logo" />
            <div  className="b-example-divider mt-1"></div>
            {this.props.location.pathname === '/pedidos-cancelados' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'4px', marginLeft:'-12px', textAlign:'center'}}>Pedidos <br/> cancelados</div>}
            {this.props.location.pathname != '/pedidos-cancelados' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'20px'}}></div>}
          </Link>

          <Link to="/pedidos-por-surtir" className="nav-link" >
            <img className={this.props.location.pathname === '/pedidos-por-surtir' ? "":"imgs"} src={porSurtir} alt="cancelaciones logo" />
            <div  className="b-example-divider mt-1"></div>
            {this.props.location.pathname === '/pedidos-por-surtir' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'4px', marginLeft:'-12px', textAlign:'center'}}>Pedidos <br/> por surtir</div>}
            {this.props.location.pathname != '/pedidos-por-surtir' && <div style={{ color: "white", fontSize: "10.6px", marginBottom:'20px'}}></div>}
          </Link>

         

        </div>
      </div>
    );
  }
}

export default withRouter(MenuLateral);

import React from "react";
import { TiMediaRecord } from "react-icons/ti";
import { IconContext } from "react-icons/lib";

export const statusFormatter = (cell, row) => {

  const StatusValues = {
    "Incompleto": {
      color: '#E30F17'
    },
    "Por Recoger": {
      color: '#D951C4'
    },
    "Confirmado": {
      color: '#149748'
    },
    "Guía Impresa": {
      color: '#AF7E04'
    },
    "Guía Confirmada": {
      color: '#2FE5BB'
    },
    "Por Confirmar": {
      color: '#FCC742'
    },
    "Cobrado": {
      color: '#1993D5'
    },
    "Imprimir Guía": {
      color: '#FA306E'
    },
    "Por Recoger": {
      color: '#D951C4'
    },
    "Entregado": {
      color: '#D0D841'
    },
    "Enviado a POS": {
      color: '#0B4CB2'
    },
    "Trabajando": {
      color: '#F79B30'
    },
    "En Camino": {
      color: '#8223C7'
    },
    "Recolectado": {
      color: '#1FC3CB'
    },
    "Listo para surtir": {
      color: '#07DE49'
    },
    "Esperando inventario": {
      color: '#0A6074'
    },
    "Default": {
      color: '#ff5400'
    }
  }

  let { color } = StatusValues[cell] || StatusValues["Default"]

  return (
    <span>
      <strong>
        <IconContext.Provider value={{ size: '1.5em', color: color }}>
          <TiMediaRecord />
        </IconContext.Provider>
      </strong> <strong>{cell}</strong>
    </span>
  );

};

export const pesoFormatter = (cell, row) => {
  return (
    <span>
      <strong> {cell} Kg</strong>
    </span>
  );
};
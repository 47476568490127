import React, { Fragment } from "react";
import "./styles/pedidoscancelados.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Search from '../Search';
import Swal from 'sweetalert2';
import pedidoPorCancelarService from "../../Services/pedidosPorCancelar.service";
import { statusFormatter, pesoFormatter } from '../CancelarPedido/formatters'
import ModalCaja from '../ModalDetailCajas'
import ModalPedido from '../modalDetailPedido'
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class PedidosCancelados extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list_orders: [],
      columns: [
        {
          dataField: "status",
          text: "Status",
          formatter: statusFormatter,
          align: 'left',
          style: {
            width: '180px'
          },
        },
        {
          dataField: "no_pedido",
          text: "No. Pedido",
          style: {
            width: '180px'
          },
        },
        {
          dataField: "fecha_entrada",
          text: "Fecha entrada",
          style: {
            width: '200px'
          }
        },
        {
          dataField: "paqueteria",
          text: "Paquetería",
          style: {
            width: '120px'
          }
        },
        {
          dataField: "",
          text: "Detalles",
          formatter: this.detallePedido,
          style: {
            width: '100px'
          },
          sort: true
        }
      ],
      pedido: [],
      contentModalPedidos: { detail: [] },
      contentModalCajas: { detail: [], orderPackaging: [] },
      showModalPedidos: false,
      showModalCajas: false,
      posicion_stepper: false,
      posicion_stepper_actualizado: false,
      selectPedido: false,
      showComprasCancelaciones: false,
    }
    this.rowSelected = []
  }

  getPedidosCancelados = async () => {
    let result = await pedidoPorCancelarService.getPedidosCancelados()
    console.log("-----------------getCancelados----------------------")
    console.log(result)
    
      this.setState({
        list_orders: result
      })
    
    
  }

  componentDidMount() {
    this.getPedidosCancelados()
  }

  detallePedido = (cell, row, rowIndex, formatExtraData) => {

    return (
      <>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-2">Detalle pedido</Tooltip>} >
          <button className="btnm" onClick={() => this.openModalPedidos(row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={22.906}
              height={29.467}
              viewBox="0 0 22.906 29.467"
            >
              <path
                className="btndetail"
                d="M0 22.73v2.421a4.322 4.322 0 004.316 4.316h14.273a4.322 4.322 0 004.316-4.316V22.73zM19.798 5.583H3.108A4.324 4.324 0 000 9.727v2.421h22.906V9.727a4.324 4.324 0 00-3.108-4.144zM19.453 4.477V1.003a.941.941 0 00-.864-1H4.316a.941.941 0 00-.864 1v3.474zM.001 13.006h22.905v8.865H.001z"
              />
            </svg>
          </button>
        </OverlayTrigger>

      
      </>
    );
  }

  openModalPedidos = (itemPedido) => {
    this.setState({ showModalPedidos: true, contentModalPedidos: itemPedido })
  }

  openModalCajas = (itemCaja) => {
    console.log(itemCaja)
    if (itemCaja.no_cajas > 0) {
      this.setState({ showModalCajas: true, contentModalCajas: itemCaja })
    } else {
      Swal.fire({
        title: 'Detalles',
        text: 'No se han armado cajas para este pedido',
        type: 'error',
        confirmButtonText: 'cerrar',
        confirmButtonColor: 'red'
      });
    }
  }

  closeModal = () => {
    this.setState({ contentModalPedidos: { detail: [] }, showModalPedidos: false, showModalCajas: false, contentModalCajas: { detail: [], orderPackaging: [] } })
  }

  render() {
    return (
      <div className="pedidosCancelados">
        <header>
          Pedidos cancelados
        </header>
        <div className="table-responsive-sm">
          <div style={{ height: '60vh', overflow: 'auto', position: 'relative' }} className="OrderListTable">
            <ToolkitProvider
              keyField="id"
              data={this.state.list_orders}
              columns={this.state.columns}
              search
            >
              {(props) => (
                <Fragment>
                  <Search {...props.searchProps} />
                  <BootstrapTable
                    {...props.baseProps}
                    keyField="id"
                  />
                </Fragment>
              )}
            </ToolkitProvider>
          </div>
        </div>

        <ModalPedido
          showModal={this.state.showModalPedidos}
          closeModal={this.closeModal}
          detallesPedido={this.state.contentModalPedidos}
        />

        <ModalCaja
          showModal={this.state.showModalCajas}
          closeModal={this.closeModal}
          detallesCajas={this.state.contentModalCajas}
        />
      </div>
    )
  }

}

export default PedidosCancelados;

import axios from 'axios';

let BASE_URL = process.env.REACT_APP_API_URL;

if (document.location.hostname.indexOf("cloudherdez.com") != -1) {

  if (document.location.origin.indexOf("https") != -1)
    BASE_URL = "https://thinkcares-bbp-nutrisa-ecommerce-back-end-api.ocom.cloudherdez.com";
  else
    BASE_URL = "http://thinkcares-bbp-nutrisa-ecommerce-back-end-api.ocom.cloudherdez.com";

}

else {

  BASE_URL = process.env.REACT_APP_API_URL;
}

//const BASE_URL = "https://24981668-review-quality-ysj4m9.34.67.224.36.nip.io";

const API = axios.create({
  baseURL: BASE_URL,
});

// Add a request interceptor
API.interceptors.request.use(function (config) {
  // Do something before request is sent
  const loggedIn = JSON.parse(window.localStorage.loggedIn)

  if (loggedIn) {
    const jwt = JSON.parse(window.localStorage.jwt);
    const userData = JSON.parse(window.localStorage.userData)
    config.headers.role = userData.role || '';
    config.headers.authorization = `Bearer ${jwt}` || "";
  }


  return config;
}, function (error) {
  // Do something with request error
  console.log(error)
  return Promise.reject(error);
});


API.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // console.log("error");
  console.log(error);
  if (error?.response?.status == 401) {

    window.localStorage.clear();
    document.location.href = "/login";
  }

  return Promise.reject(error);
});

export default API;
import React, { Fragment } from "react";
import "./styles/cancelarPedido.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Search from '../Search';
import Swal from 'sweetalert2';
import ScannerModalCanceled from '../ScannerModalCanceled/index'
import pedidoPorCancelarService from "../../Services/pedidosPorCancelar.service";
import { statusFormatter, pesoFormatter } from './formatters'
import ModalCaja from '../ModalDetailCajas'
import ModalPedido from '../modalDetailPedido'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import OrderCanceled from '../OrderCanceled/index';

class Cancelaciones extends React.Component {
  constructor(props) {
    super(props);
    this.handlePedido = this.handlePedido.bind(this);
    this.state = {
      list_orders: [],
      columns: [
        {
          dataField: "status",
          text: "Status",
          formatter: statusFormatter,
          align: 'left',
          style: {
            width: '180px'
          },
        },
        {
          dataField: "no_pedido",
          text: "No. Pedido",
          style: {
            width: '180px'
          },
        },
        {
          dataField: "fecha_entrada",
          text: "Fecha entrada",
          style: {
            width: '200px'
          }
        },

        {
          dataField: "paqueteria",
          text: "Paquetería",
          style: {
            width: '120px'
          }
        },
        {
          dataField: "",
          text: "Detalles",
          formatter: this.detallePedido,
          style: {
            width: '100px'
          },
          sort: true
        }
      ],
      cancelarPedidos: [],
      contentModalPedidos: { detail: [] },
      contentModalCajas: { detail: [], orderPackaging: [] },
      showModalPedidos: false,
      showModalCajas: false,
      showModalScanner: false,
      posicion_stepper: false,
      posicion_stepper_actualizado: false,
      selectPedido: false,
      show: false,
      rowSelected: [],
    }
    this.bootstrapTableRef=null
  }

  getPedidosPorConfirmar = async () => {
    let result = await pedidoPorCancelarService.getPorConfirmar()
    console.log("---------getPorConfirmar------------")
    this.setState({
      list_orders: result
    })
  }
  handlePedido(pedido) {
    // console.log("num pedido");

    const ped = this.state.list_orders.filter(element => element.no_pedido === pedido.replace(/ /g,""));
    // console.log(ped[0])
    this.setState({ pedido: ped[0] })
    if(ped[0] === "undefined" || ped[0]===undefined){
      Swal.fire({
        title: 'Error al confirmar pedido',
        text: 'No existe ese pedido',
        type: 'error',
        confirmButtonText: 'cerrar',
        confirmButtonColor: 'red'
      });
    }else{
      console.log("pedido")
      console.log(ped)
      this.setState({
        rowSelected:ped,
        showModalScanner:false,
        showPedidosPorCancelar:true
      })
    }
   

  }

  componentDidMount() {
    this.getPedidosPorConfirmar()
  }

  detallePedido = (cell, row, rowIndex, formatExtraData) => {

    return (
      <>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-2">Detalle pedido</Tooltip>} >
          <button className="btnm" onClick={() => this.openModalPedidos(row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={22.906}
              height={29.467}
              viewBox="0 0 22.906 29.467"
            >
              <path
                className="btndetail"
                d="M0 22.73v2.421a4.322 4.322 0 004.316 4.316h14.273a4.322 4.322 0 004.316-4.316V22.73zM19.798 5.583H3.108A4.324 4.324 0 000 9.727v2.421h22.906V9.727a4.324 4.324 0 00-3.108-4.144zM19.453 4.477V1.003a.941.941 0 00-.864-1H4.316a.941.941 0 00-.864 1v3.474zM.001 13.006h22.905v8.865H.001z"
              />
            </svg>
          </button>
        </OverlayTrigger>

      </>
    );
  }

  openModalPedidos = (itemPedido) => {
    this.setState({ showModalPedidos: true, contentModalPedidos: itemPedido })
  }

  openModalCajas = (itemCaja) => {
    console.log(itemCaja)
    if (itemCaja.no_cajas > 0) {
      this.setState({ showModalCajas: true, contentModalCajas: itemCaja })
    } else {
      Swal.fire({
        title: 'Detalles',
        text: 'No se han armado cajas para este pedido',
        type: 'error',
        confirmButtonText: 'cerrar',
        confirmButtonColor: 'red'
      });
    }
  }

  closeModal = () => {
    this.setState({ contentModalPedidos: { detail: [] }, showModalPedidos: false, showModalCajas: false, contentModalCajas: { detail: [], orderPackaging: [] } })
  }

  //metodo para cuando se selecciona un elemento en el grid
  handleOnSelect = (row, isSelect) => {
    console.log(isSelect)
    let tempRowSelected = [...this.state.rowSelected]

    if (isSelect) {
      tempRowSelected.push(row)
      this.setState({ posicion_stepper: !this.state.posicion_stepper })
    } else {
      tempRowSelected = tempRowSelected.filter(x => x.id !== row.id)
      this.setState({ posicion_stepper: !this.state.posicion_stepper })
    }
    // Habilitar button ticket stepper
    this.setState({
      selectPedido: (tempRowSelected.length > 0),
      rowSelected: tempRowSelected,
    })

  }
  handleModalScanner = () => {
    this.setState({ showModalScanner: !this.state.showModalScanner, pedido: [] })
  }
  //metodo para cuando se seleccione todos los elemento en el grid
  handleOnSelectAll = (isSelect, rows) => {
    let tempRowSelected = [];

    if (isSelect) {
      tempRowSelected = rows;
    } else {
      tempRowSelected = [];
    }

    // Habilitar button ticket stepper
    this.setState({
      selectPedido: (tempRowSelected.length > 0),
      rowSelected: tempRowSelected,
    })
  }

  clearDataSelected = () => { this.setState({ rowSelected: [] }) }

  actualizarRow = () => {
    this.setState({ showPedidosPorCancelar: false });
    this.bootstrapTableRef.selectionContext.selected = [];
    this.clearDataSelected()
    this.getPedidosPorConfirmar();
  
  };

  handlePedidosPorCancelar = () => {
    this.setState({ showPedidosPorCancelar: !this.state.showPedidosPorCancelar })
  }

  render() {
    const selectRow = {
      mode: "checkbox",
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      
    };

    return (
      <div className="ClassCancelarPedidos">
        <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "4.3vh", marginBottom: "7.3vh" }}>
          <div style={{ cursor: "pointer" }}>
            <img
              src={this.state.selectPedido ? "/img/steps/step-3-active.svg" : "/img/steps/step-3.svg"}
              onClick={() => {
                
                if (this.state.rowSelected.length === 1) {
                  this.setState({ showPedidosPorCancelar: true })
                } 
                if(this.state.rowSelected.length > 1){
                  this.setState({showModalScanner:true})
                }
              }
              }

            />
          </div>
          <label style={this.state.selectPedido ? TxtColorAc : TxtColorIn}>Cancelar pedido</label>
        </div>

        <div className="table-responsive-sm">
          <div style={{ height: '60vh', overflow: 'auto', position: 'relative' }} className="OrderListTable">
            <ToolkitProvider
              keyField="id"
              data={this.state.list_orders}
              columns={this.state.columns}
              search
            >
              {(props) => (
                <Fragment>
                  <Search {...props.searchProps} />
                  <BootstrapTable
                  ref={(i) => this.bootstrapTableRef = i}
                    selectRow={selectRow}
                    {...props.baseProps}
                    keyField="id"
                  />
                </Fragment>
              )}
            </ToolkitProvider>
          </div>
        </div>

        <ScannerModalCanceled
          showModalScanner={this.state.showModalScanner}
          handleModalScanner={() => { this.handleModalScanner() }}
          handlePedido={this.handlePedido}
          nu_ped={this.state.nu_ped}
          guia={this.state.guia}
          handleGuia={this.handleGuia}
          confirmarGuia={this.confirmarGuia}
          list_orders={this.state.list_orders}
        />

        <ModalPedido
          showModal={this.state.showModalPedidos}
          closeModal={this.closeModal}
          detallesPedido={this.state.contentModalPedidos}
        />

        <ModalCaja
          showModal={this.state.showModalCajas}
          closeModal={this.closeModal}
          detallesCajas={this.state.contentModalCajas}
        />

        <OrderCanceled
          dataSelected={this.state.rowSelected}
          clearData={this.clearDataSelected}
          updateRow={this.actualizarRow}
          handlePurchase={this.handlePedidosPorCancelar}
          showPurchase={this.state.showPedidosPorCancelar}
        />
      </div>
    )
  }

}

const TxtColorIn = {
  marginTop: "5px",
  textAalign: "left",
  letterSpacing: "0.35px",
  color: "#969393"
}

const TxtColorAc = {
  marginTop: "5px",
  textAalign: "left",
  letterSpacing: "0.35px",
  color: "#F79B30",
  fontWeight: 500,
}

export default Cancelaciones;

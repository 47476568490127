import React from 'react';
import './HomeNutrisa.css';
import home_logo from './img/logo.svg';
import image_box from './img/Imagen 76.png';
import Swal from 'sweetalert2';
import GoogleLogin from 'react-google-login';
import loginService from '../../Services/login.service';
import jwt_decode from "jwt-decode";

class Login extends React.Component {
    constructor() {
        super()
        this.state = {}

    }

    render() {
        const successResponse = (googleResponse) => {
            loginService.verify(googleResponse)
            .then(response => {
                console.log(response)
                const jwt = response.access_token
                const userData = jwt_decode(jwt)
                window.localStorage.setItem('jwt',JSON.stringify(jwt))
                window.localStorage.setItem('userData',JSON.stringify(userData))
                window.localStorage.setItem('loggedIn', true);
                window.location.href = "/pedidos";
            })
            .catch(error => {
                console.log(error)
            });
        }

        return (
            <div className="HomeNutrisa__container container-fluid">
                <div className="row vh-100 justify-content-around align-items-center">

                    <div className="col-12 col-md-5 col-lg-5 HomeNutrisa__title">
                        <p className="text-center">
                            Orquestador <br /> Comercial
                        </p>
                        <img src={home_logo} alt="nutrisa_home_logo"
                            className="img-fluid float-end HomeNutrisa__home_logo" />
                    </div>

                    <div className="HomeNutrisa__b-example-divider col-12 col-lg-1">
                        <span className="HomeNutrisa__line_vertical">
                        </span>
                    </div>

                    <div className="col-12 col-md-5 col-lg-5 HomeNutrisa__google_component" >

                        <GoogleLogin
                            clientId="440862947785-6ichl5dm98j010rbj9b64levkvm9agqf.apps.googleusercontent.com"
                            className="google-button-login"
                            buttonText="Ingresa con google"
                            onSuccess={successResponse}
                            onFailure={successResponse}
                            icon={false}
                            cookiePolicy={'single_host_origin'}>
                            <div className="HomeNutrisa__button_login">
                                <img src="https://img.icons8.com/color/48/000000/google-logo.png" 
                                    alt="Google logo" className="HomeNutrisa__google_component_img" />
                                <span> Inicia sesión  con  Google </span >
                            </div>

                        </GoogleLogin>

                        <div className="text-right HomeNutrisa__box">
                            <img src={image_box} alt="imageBox"
                                className="img-fluid float-end HomeNutrisa__box_image"/>
                        </div>

                    </div>

                </div>

            </div>

        )
    }
}

export default Login
import React, { Component } from 'react';
import './App.css';
import { Button } from 'react-bootstrap';
import { Router as Router, Route, Switch } from 'react-router-dom';
import Home from './Components/Home'
import Header from './Components/HeaderNutrisa/index'
import Footer from './Components/Footer/index'
import history from './history.js'
import Historial from './Components/Historial/index'
import Register from './Components/Register/index'
import MobileMenu from './Components/MobileMenu/index'
import Pedidos from './Components/Pedidos/index'
import Inventario from './Components/Inventario/index'
import Menu from './Components/Menu/index'
import MenuLateral from './Components/MenuLateral/index'
import SeguimientoPaqueterias from './Components/SeguimientoPaqueterias/index'
import PedidosIncompletos from './Components/PedidosIncompletos/index'
import ErrorPedidos from './Components/ErrorPedidos/index'
import Cancelaciones from './Components/CancelarPedido/index'
import PedidosCancelados from './Components/PedidosCancelados/index'
import PedidosPorSurtir from './Components/PedidosPorSurtir/index'
import { AppContextProvider } from './Components/appContext'
import { SlowBuffer } from 'buffer';
import Login from './Components/Login';
import { getTokenEcommerce } from './firebase';
import FirebaseTokenService from './Services/firebaseToken.service';

class App extends Component {
  constructor() {
    super()
    this.state = {
      loggedIn: ((localStorage.getItem('loggedIn') && JSON.parse(localStorage.getItem('loggedIn'))) || false),
      user: {},
      isOpen: false
    }
  }
  setTokenFound = (tokenFound) => {
    this.setState({
      ...this.state,
      tokenFound: tokenFound
    })
  }
  handleMenuOpen() {
    this.setState({
      isOpen: true
    })
  }
  setLogin = (user, response) => {
    this.setState({
      user: user
    })
    window.localStorage.setItem('loggedIn', true);
    window.localStorage.setItem('userData', JSON.stringify(user));
    history.push("/")
  }
  componentDidMount() {
    getTokenEcommerce(this.setTokenFound);
    let jwt = window.localStorage.jwt;
    if (jwt) {
      window.localStorage.setItem('loggedIn', true);
      let firebaseToken = window.localStorage.firebaseToken;
      if (firebaseToken) {
        // guardar token para recibir notificaciones
        const user = JSON.parse(window.localStorage.userData);
        const token = {
          userid: user.sub,
          token: firebaseToken
        }
        FirebaseTokenService.postToken(token);
      }
    } else {
      window.localStorage.setItem('loggedIn', false);
    }
  }

  render() {
    return (
      <AppContextProvider value={this.state}>

        <Router history={history}>
          <Switch>

            {/*Verificar si es correcto dejar el componente home a este nivel*/}
            <Route exact path="/login" component={Login} />

            <div className="d-flex">

              
              
              <Menu /> 
              <div className="page-content-wrapper" style={{ width: "100%", height: "100%", overflow: "auto",paddingTop:"5%" }}>
                <div className="container-fluid">

                  
                {/* <Header></Header> */}

                  <section className="section">
                    <Route exact path="/" component={Pedidos} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/pedidos" component={Pedidos} />
                    <Route exact path="/inventario" component={Inventario} />
                    <Route exact path="/historial" component={Historial} />
                    <Route exact path="/seguimiento-paqueterias" component={SeguimientoPaqueterias} />
                    <Route exact path="/pedidos-error" component={ErrorPedidos} />
                    <Route exact path="/pedidos-incompleto" component={PedidosIncompletos} />
                    <Route exact path="/cancelar-pedido" component={Cancelaciones} />
                    <Route exact path="/pedidos-cancelados" component={PedidosCancelados} />
                    <Route exact path="/pedidos-por-surtir" component={PedidosPorSurtir} />
                    <Route exact path="/pedidos-por-surtir/:saleorderid" render={(props) => <PedidosPorSurtir {...props} /> } />
                  </section>
                </div>
              </div>


            </div>
          </Switch>
        </Router>

      </AppContextProvider>
    );
  }
}

export default App;

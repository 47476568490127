import React,{PureComponent, Fragment} from 'react'
import Modal from 'react-bootstrap/Modal'


export default class AvisoPrivacidadModal extends PureComponent {
    constructor(props, context) {
      super(props, context);

  
      this.state = {
        show: false,
      };
    }
  
    render() {
      return (
        <Fragment>
  
          <Modal size="lg" show={this.props.modal} onHide={this.props.handleModal}>
            <Modal.Header closeButton>
              <Modal.Title>AVISO DE PRIVACIDAD INTEGRAL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
           <b> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas congue quisque egestas diam in. Nec nam aliquam sem et tortor consequat id porta nibh. Pellentesque id nibh tortor id aliquet lectus proin nibh. Risus sed vulputate odio ut enim blandit. Mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Dui vivamus arcu felis bibendum ut tristique et. Massa sapien faucibus et molestie ac feugiat sed. Magna fringilla urna porttitor rhoncus. Etiam tempor orci eu lobortis elementum nibh tellus molestie nunc. Gravida in fermentum et sollicitudin ac orci. Neque gravida in fermentum et.</b>

            <b>Ut venenatis tellus in metus vulputate. Enim tortor at auctor urna nunc id. Sit amet luctus venenatis lectus. Blandit cursus risus at ultrices mi tempus imperdiet nulla. Ac tortor dignissim convallis aenean et tortor at risus viverra. Leo duis ut diam quam nulla porttitor massa id. Ultrices tincidunt arcu non sodales. Nulla porttitor massa id neque aliquam vestibulum. Sed augue lacus viverra vitae congue eu consequat. Ornare lectus sit amet est placerat. Consectetur libero id faucibus nisl tincidunt eget nullam non nisi. Blandit turpis cursus in hac habitasse platea dictumst quisque sagittis. Porta lorem mollis aliquam ut porttitor leo.</b>

            <b>Eu turpis egestas pretium aenean. Velit dignissim sodales ut eu. Dictum varius duis at consectetur lorem donec massa sapien faucibus. Dolor morbi non arcu risus quis varius quam. Morbi tristique senectus et netus et malesuada fames. Sed nisi lacus sed viverra tellus in hac habitasse platea. At imperdiet dui accumsan sit amet nulla facilisi morbi tempus. Lorem ipsum dolor sit amet. Faucibus turpis in eu mi bibendum neque egestas congue. Enim facilisis gravida neque convallis a cras semper auctor neque. Justo nec ultrices dui sapien. Neque ornare aenean euismod elementum nisi quis eleifend. Massa tincidunt dui ut ornare lectus. Nibh tellus molestie nunc non blandit massa enim. Egestas integer eget aliquet nibh praesent tristique.</b>

            </Modal.Body>
            <Modal.Footer>
              <button style={{backgroundColor: "#FFC656", color: "white"}}className="btn mr-auto ml-auto" variant="primary" onClick={this.props.handleModal}>
                Cerrar
              </button>
            </Modal.Footer>
          </Modal>
        </Fragment>
      );
    }
  }
import React, { PureComponent } from 'react'
import AvisoPrivacidad from '../Avisoprivacidadmodal/index'
import Terminos from '../TerminosModal/index'
import Switch from '@material-ui/core/Switch';
import './index.css'
export default class Register extends PureComponent {
    constructor(){
        super()
        this.state={
                nombre: "",
                correo: "",
                telefono:"",
                estado: "",
                password:"",
                verificar:"",
                checked:true
        }
        this.handleAvisoModal=this.handleAvisoModal.bind(this);
        this.handleModal=this.handleModal.bind(this)
        this.handleInput=this.handleInput.bind(this)
    }
    handleRegister=(e)=>{
        e.preventDefault()
    }
    handleInput(e) {
        const { value, name } = e.target;
      
        this.setState({
            [name]: value
        })
        console.log(this.state)
    }
    handleModal(){
        this.setState({
            modal: !this.state.modal
        })
    }
 
  
    handleAvisoModal() {
        this.setState({
            avisomodal: !this.state.avisomodal
        })
    }
    render() {
        
        return (
            <>
                <form onSubmit={(e) =>this.handleRegister(e) } className="login-content">
                    <div style={{ display: "flex", flexDirection: "column", padding: "10%" }} className="container-register">

                        <input
                            className="mt-3 form-control"
                            type="text"
                            required
                            placeholder="Nombre"
                            onChange={this.handleInput}
                            name="nombre"
                        
                        />
                        <input
                            className="mt-3 form-control"
                            type="email"
                            placeholder="Correo"
                            required
                            onChange={this.handleInput}
                            name="correo"
                        />
                         <input
                            className="mt-3 form-control"
                            type="tel"
                            placeholder="Telefono"
                            required
                            onChange={this.handleInput}
                            name="telefono"
                        />
                         <select value={this.state.estado} name="estados" className="form-control mt-3" style={{borderColor: "#B5E1FA", color:"gray"}} required onChange={(e)=> {this.setState({estado:e.currentTarget.value})}}>
                                <option value="" selected disabled hidden>Estado de la República</option>
                                <option value="Aguascalientes">Aguascalientes</option>
                                <option value="Baja California">Baja California Norte</option>
                                <option value="Baja California Sur">Baja California Sur</option>
                                <option value="Campeche">Campeche</option>
                                <option value="Chiapas">Chiapas</option>
                                <option value="Chihuahua">Chihuahua</option>
                                <option value="Ciudad de México">Ciudad de México</option>
                                <option value="Coahuila">Coahuila</option>
                                <option value="Colima">Colima</option>
                                <option value="Durango">Durango</option>
                                <option value="Estado de México">Estado de México</option>
                                <option value="Guanajuato">Guanajuato</option>
                                <option value="Guerrero">Guerrero</option>
                                <option value="Hidalgo">Hidalgo</option>
                                <option value="Jalisco">Jalisco</option>
                                <option value="Michoacán">Michoacán</option>
                                <option value="Morelos">Morelos</option>
                                <option value="Nayarit">Nayarit</option>
                                <option value="Nuevo León">Nuevo León</option>
                                <option value="Oaxaca">Oaxaca</option>
                                <option value="Puebla">Puebla</option>
                                <option value="Querétaro">Querétaro</option>
                                <option value="Quintana Roo">Quintana Roo</option>
                                <option value="San Luis Potosí">San Luis Potosí</option>
                                <option value="Sinaloa">Sinaloa</option>
                                <option value="Sonora">Sonora</option>
                                <option value="Tabasco">Tabasco</option>
                                <option value="Tamaulipas">Tamaulipas</option>
                                <option value="Tlaxcala">Tlaxcala</option>
                                <option value="Veracruz">Veracruz</option>
                                <option value="Yucatán">Yucatán</option>
                                <option value="Zacatecas">Zacatecas</option>
                            </select>
                            <input
                                required
                                type="password"
                                name="password"
                                minLength="8"
                                onChange={(e) => { this.handleInput(e) }}
                                className="mt-3 form-control"
                                placeholder="Contraseña"
                            />
                            <input
                                name="verificar"
                                type="password"
                                onChange={(e) => { this.handleInput(e) }}
                                minLength="8"
                                required
                                className="mt-3 form-control"
                                placeholder="Verificación de contraseña"
                            />
                        <div style={{display:this.state.verificar === this.state.password ? "none":""}} class="alert alert-danger" role="alert">
                            Sus contraseñas deben coincidir
                        </div>
                        <div style={{display:"flex"}}>
                            <Switch
                                onClick={(e) => {
                                    this.setState({ Checked: e.target.checked })
                                }}
                                value={this.state.checked}
                                required
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <label className="containerantojo mt-2">Acepto los <u onMouseDown={() => this.handleModal()}>términos y condiciones </u> y <u onMouseDown={() => this.handleAvisoModal()}>el aviso de privacidad</u></label>
                        </div>
                       

                        <button disabled={ this.state.verificar === this.state.password ? false:true} className="btn btn-success mt-3">Registrarse</button>
                    </div>
                </form>

                <Terminos
                modal = {this.state.modal}
                handleModal = {() => this.handleModal()}
                />
                <AvisoPrivacidad
                modal = {this.state.avisomodal}
                handleModal = {() => this.handleAvisoModal()}
                />
            </>
        )
    }
}
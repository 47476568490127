import React, { PureComponent } from 'react'
import Form from "react-jsonschema-form";
import './index.css'

export default class Home extends PureComponent {

    render() {
        const schema = {
            title: "Todo",
            type: "object",
            required: ["title"],
            properties: {
              title: {type: "string", title: "Title", default: "A new task"},
              done: {type: "boolean", title: "Done?", default: false}
            }
          };
          const log = (type) => console.log.bind(console, type);
          const formData = {
            title: "First task",
            done: true
          };
          const onSubmit = ({formData}, e) => console.log("Data submitted: ",  formData);

        return (
            <>
             <Form 
             schema={schema}
            formData={formData}
            onSubmit={onSubmit}
        onChange={log("changed")}
        onSubmit={log("submitted")}
        onError={log("errors")} />
               
            </>
        )

    }

}  